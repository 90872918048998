/* eslint-disable no-lone-blocks */
import { Divider, Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { FaFilePdf, FaChartPie, FaPlus } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { IModel } from '../../interfaces'
import { ComparativoChart } from '../../Components/ComparativoChart'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { ComparativoChartPDF } from '../../Components/ComparativoDemografiaPDF/Components/ComparativoChartPDF'
import { ComparativoDemografiaPDF } from '../../Components/ComparativoDemografiaPDF'

interface iProps {
  Model: IModel
  tabAtiva: boolean
}

export const TabComparativaDemografia: React.FC<iProps> = ({ Model, tabAtiva }) => {
  const [GraficosDemografia, setGraficosDemografia] = useState<Array<{ nome: string, grafico: string }>>([])

  function GetGraficosDemografia(): any {
    const arr: Array<{ nome: string, grafico: string }> = []
    return Model.comparativoDemografia?.map((e, i) => {
      if (e.length === 0) {
        return null
      }
      return (
        <Flex w={'1000px'} h={'1000px'} pos={'absolute'} top={-1000 * (i + 1)} left={-1000 * (i + 1)}>
          <ComparativoChartPDF
            id={`comparativo-chart-demografia-pdf-${i}`}
            dados={e}
            setGrafico={(str) => { arr.push({ grafico: str, nome: e[0].titulo }); if (arr.length === Model.comparativoDemografia?.length) { setGraficosDemografia(arr) } }}
          />
        </Flex>
      )
    })
  }

  return (
    <>
      {tabAtiva && GetGraficosDemografia()}
      <Flex justifyContent={'space-between'} alignItems={'end'} mb={'2rem'}>
        <PDFDownloadLink
          document={
            <ComparativoDemografiaPDF
              model={Model}
              graficosDemografia={GraficosDemografia}
            />
          }
          fileName={'exportar_comparativo_demografias_pec.pdf'}
        >
          {({ loading }) =>
            (loading || GraficosDemografia.length !== Model.comparativoDemografia?.filter((e, i) => e.length !== 0).length)
              ? (
                <ButtonCmp VarColor='Green2' leftIcon={<FaFilePdf/>} w={'19rem'} isLoading>Relatório PDF</ButtonCmp>
                )
              : <ButtonCmp VarColor='Green2' leftIcon={<FaFilePdf/>} w={'19rem'}>Relatório PDF</ButtonCmp>

          }
        </PDFDownloadLink>
      </Flex>
      {Model.comparativoDemografia?.map((e, i) => {
        if (e.length === 0) {
          return null
        }
        return (
          <Flex flexDir={'column'} gap={'.75rem'}>
            <Flex gap={'.75rem'} alignItems='center'>
              <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
                <FaChartPie fontSize={'2rem'} color={'var(--Azul)'}/>
              </Flex>
              <h3>{e[0].titulo}</h3>
            </Flex>
            <ComparativoChart
              dados={[e[1], e[0]]}
              id={`comparativo-demografia-${i}`}
            />
          </Flex>
        )
      })}
      <Divider borderColor={'var(--AzulOpaco)'} my={'2rem'} opacity={'1'}/>
      <Flex gap={'.25rem'} alignItems={'center'} flexDir={'column'} maxW={'66rem'} margin={'0 auto'} justifyContent={'center'}>
        <h3 style={{ textAlign: 'center' }}>Agora você pode adicionar planos de ação para trabalhar as questões apontadas em sua pesquisa de engajamento!</h3>
        <span style={{ textAlign: 'center' }}>Recomendamos que foque principalmente nas questões que afetam o engajamento na sua organização, ou seja, as questões que os respondentes realmente consideram importantes.</span>
        <ButtonCmp leftIcon={<FaPlus/>} VarColor='Green2' w={'12rem'} my={'.75rem'} onClick={() => { window.location.href = `${process.env.REACT_APP_URL_SIGAH}/Home/Plano` }}>Adicionar plano de ação</ButtonCmp>
      </Flex>

    </>
  )
}
