/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Divider, Flex, FormControl, Radio, RadioGroup, Stack, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaAngleLeft, FaAngleRight, FaEye } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { EditorTexto } from '../../../../components/EditorTexto/Index'
import { InputCmp } from '../../../../components/InputCmp'
import LoadingContainer from '../../../../components/LoadingContainer'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { ButtonTag } from '../../../PesquisaPercepcao/EnviarPesquisa/Components/ButtonTag'
import { TimelineCriarPEC } from '../Components/Timeline'
import { ModalTag } from './Components/ModalTag/Index'
import { OutlineContainer } from './styles'

type iLocationProps = {
  pesquisaId: string
  trilhaId: string
  processoId: string
}

export interface iPutConfiguracao {
  dataInicio: string
  dataFim: string
  msgAbertura: string
  msgResposta: string
  msgEmail: string
  url: string
  opcaoEncerrarPesquisa: number
}

export const ConfiguracaoPEC: React.FC = () => {
  const nav = useNavigate()
  const param = useParams<iLocationProps>()
  const { pathname } = useLocation()
  const textoPadraoAbertura = '<p>Bem-vindo à pesquisa de engajamento da empresa <strong>[NOME DA EMPRESA]</strong>.</p>'
  const textoPadraoEmail = '<h3>Pesquisa de Engajamento</h3><p>Caro colaborador,</p><p>Você está recebendo o link para o preenchimento da Pesquisa de Engajamento Organizacional. A pesquisa é simples e rápida e todas as informações serão tratadas de forma estritamente confidencial e sem identificação. <u>Nenhuma pessoa da EMPRESA terá acesso aos resultados individuais da pesquisa.</u> <br/>O prazo para o preenchimento é de [DATA INICIO] até o dia [DATA FIM].</p><p>[LINK]</p><p>A sua participação é fundamental para o sucesso dessa iniciativa.</p><p>Contamos com você!</p>'
  const toast = useToast()
  const [ModalTagIsOpen, setModalTagIsOpen] = useState(false)
  const [IsLoading, setIsLoading] = useState(false)
  const [Model, setModel] = useState<iPutConfiguracao>({
    dataFim: '',
    dataInicio: '',
    msgAbertura: textoPadraoAbertura,
    msgEmail: textoPadraoEmail,
    msgResposta: 'Obrigado por participar!',
    opcaoEncerrarPesquisa: 2,
    url: ''
  })

  const [NaoDefinirDataEncerramento, setNaoDefinirDataEncerramento] = useState(false)

  function getConfiguracao(): void {
    setIsLoading(true)
    appApi.get(`Pec/${param.pesquisaId as string}/Configuracao`)
      .then(res => {
        setModel(res.data)
        if (!res.data.msgAbertura || !res.data.msgEmail) {
          setModel({ ...res.data, msgAbertura: res.data.msgAbertura ?? textoPadraoAbertura, msgEmail: res.data.msgEmail ?? textoPadraoEmail })
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function UpdateConfiguracao(previsualizar: boolean): void {
    if (Model.dataFim === '' || !Model.dataFim || Model.dataInicio === '' || !Model.dataInicio) {
      toast({
        duration: 3000,
        title: 'Preencha corretamente os campos de início e fim da pesquisa',
        status: 'error'
      })
      return
    }

    setTimeout(() => {
      appApi.put(`Pec/${param.pesquisaId}/Configuracao`, Model)
        .then(() => {
          previsualizar
            ? nav(`/Engajamento/Pec/f/${param.pesquisaId}/Previsualizar`)
            : nav(`/Engajamento/Pec/Convites/${param.pesquisaId}`)
        })
        .catch(err => {
          console.log(err)
          toast({
            title: 'Há campos sem preenchimento',
            status: 'error',
            isClosable: false,
            position: 'top-right'
          })
        })
    }, 1000)
  }

  useEffect(() => {
    getConfiguracao()
  }, [])

  useEffect(() => {
    if (Model.dataFim === undefined || Model.dataFim === null) {
      setNaoDefinirDataEncerramento(true)
    }
  }, [Model.dataFim])

  return (
    <Body>
      <ModalTag
        onClose={() => setModalTagIsOpen(false)}
        isOpen={ModalTagIsOpen}
      />
      <TimelineCriarPEC formularioAprovado color={['var(--Azul)', 'var(--Azul)', '.', '.', '.', '.']}/>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <h2>Configure a sua pesquisa</h2>
      </Flex>
      <WhiteContainer margin='.5rem 0'>
        <LoadingContainer linhas={10} my='.5rem' loading={IsLoading}>
          <OutlineContainer>
            <h2>Período</h2>
            <Flex flexDir={'column'} gap={'1rem'}>
              <Flex w={'100%'} justifyContent={'space-between'} alignItems={'end'}>
                <Flex alignItems={'end'} gap={'.5rem'} w={'45%'}>
                  <InputCmp
                    label='Início da pesquisa'
                    min={new Date().toISOString().slice(0, -8)}
                    max={Model?.dataFim}
                    value={Model?.dataInicio ?? ''}
                    OnChange={(e) => setModel({ ...Model, dataInicio: e })}
                    type={'datetime-local'}
                  />
                </Flex>
                <Divider orientation='vertical' height={'3rem'} borderColor={'var(--c6)'}/>
                <Flex alignItems={'end'} gap={'.5rem'} w={'45%'}>
                  <InputCmp
                    label='Fim da pesquisa'
                    min={Model?.dataInicio}
                    value={Model.dataFim ?? ''}
                    OnChange={(e) => setModel({ ...Model, dataFim: e })}
                    type={'datetime-local'}
                    marginRight={'.5rem'}
                    max={'2099-12-31T23:59'}
                  />
                </Flex>
              </Flex>
            </Flex>
          </OutlineContainer>
        </LoadingContainer>
      </WhiteContainer>
      <WhiteContainer margin='.5rem 0'>
        <LoadingContainer linhas={10} my='.5rem' loading={IsLoading}>
          <OutlineContainer>
            <Flex flexDir={'column'}>
              <Flex justifyContent={'space-between'}>
                <Flex flexDir={'column'}>
                  <h3>Texto de abertura da pesquisa</h3>
                  <Text
                    fontWeight={'500'}
                    fontSize='0.875rem'
                    style={{ color: 'var(--a4)', marginBottom: '.5rem' }}
                  >O respondente irá visualizar esse texto antes de começar a preencher a pesquisa, para visualizar exatamente como irá ficar, você pode pré-visualizar clicando no botão abaixo da caixa de edição de texto.</Text>
                </Flex>
                <ButtonTag onClick={() => setModalTagIsOpen(true)}>Saiba como utilizar as TAGs</ButtonTag>
              </Flex>
              <EditorTexto isLoading={IsLoading} initialValue={Model.msgAbertura ?? ''}
                onChange={(e: string) => setModel({ ...Model, msgAbertura: e })}
              />

            </Flex>
            <Flex marginTop={'.75rem'} gap={'.5rem'} justifyContent={'end'}>
              <ButtonCmp
                onClick={() => { UpdateConfiguracao(true) }}
                VarColor='Rosa' leftIcon={<FaEye />}
              >Pré-visualização do início da pesquisa</ButtonCmp>
            </Flex>
          </OutlineContainer>
        </LoadingContainer>
      </WhiteContainer>

      <WhiteContainer margin='.5rem 0'>
        <LoadingContainer linhas={10} my='.5rem' loading={IsLoading}>
          <OutlineContainer>
            <Flex flexDir={'column'}>
              <Flex justifyContent={'space-between'}>
                <Flex flexDir={'column'}>
                  <h3>Texto do Email convite da pesquisa</h3>
                  <Text
                    fontWeight={'500'}
                    fontSize='0.875rem'
                    style={{ color: 'var(--a4)', marginBottom: '.5rem' }}
                  >Você pode editar o texto do e-mail que será enviado para os colaboradores.</Text>
                </Flex>
                <ButtonTag onClick={() => setModalTagIsOpen(true)}>Saiba como utilizar as TAGs</ButtonTag>
              </Flex>
              <EditorTexto isLoading={IsLoading} initialValue={Model.msgEmail ?? ''}
                onChange={(e: string) => setModel({ ...Model, msgEmail: e })}
              />

            </Flex>
          </OutlineContainer>
        </LoadingContainer>
      </WhiteContainer>
      <WhiteContainer margin='.5rem 0'>
        <LoadingContainer linhas={10} my='.5rem' loading={IsLoading}>
          <OutlineContainer>
            <Flex flexDir={'column'}>
              <h3>Opções após encerrar pesquisa</h3>
              <Text
                fontWeight={'500'}
                fontSize={'0.875rem'}
                style={{ color: 'var(--a4)', fontSize: '14px' }}
              >Escolha uma opção abaixo para o encerramento da pesquisa</Text>
              <RadioGroup onChange={(e) => setModel({ ...Model, opcaoEncerrarPesquisa: parseInt(e) })} value={Model.opcaoEncerrarPesquisa.toString()} size={'sm'} my={'.75rem'}>
                <Stack color={'var(--a4)'} direction='column'>
                  <Radio borderColor={'var(--Gray4)'} value='0'>Redirecione o usuário para o site do sistema.</Radio>
                  <Radio borderColor={'var(--Gray4)'} value='1'>Redirecione o usuário para o site de sua empresa.</Radio>
                  <Radio borderColor={'var(--Gray4)'} value='2'>Exiba uma mensagem.</Radio>
                </Stack>
              </RadioGroup>
              {
                (Model.opcaoEncerrarPesquisa === 1) && (
                  <FormControl>
                    <InputCmp
                      label='Escreva o endereço do site:'
                      placeholder={'www.meusite.com.br'}
                      OnChange={(e) => setModel({ ...Model, url: e })}
                      value={Model.url}
                    />
                  </FormControl>
                )
              }

              {
                (Model.opcaoEncerrarPesquisa === 2) && (
                  <EditorTexto isLoading={IsLoading} initialValue={Model.msgResposta ?? ''}
                    onChange={(e: string) => setModel({ ...Model, msgResposta: e })}
                  />
                )
              }
            </Flex>
            {/* <Flex marginTop={'.75rem'} justifyContent={'end'}>
              <ButtonCmp VarColor='Green2' leftIcon={<FaSave />}>Salvar</ButtonCmp>
            </Flex> */}
          </OutlineContainer>
        </LoadingContainer>
      </WhiteContainer>
      <Flex justifyContent={'end'} gap={'.5rem'} mt={'1rem'}>
        <ButtonCmp
          onClick={() => nav(-1)}
          leftIcon={<FaAngleLeft />}
          VarColor='c6'
        >Voltar</ButtonCmp>

        <ButtonCmp
          rightIcon={<FaAngleRight />}
          VarColor='Green2'
          onClick={() => UpdateConfiguracao(false)}
        >Salvar e ir para o próximo</ButtonCmp>
      </Flex>
    </Body>
  )
}
