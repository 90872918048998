/* eslint-disable @typescript-eslint/no-unused-vars */
import { Divider, Flex, Img, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { FaFilePdf, FaChartPie, FaChartBar, FaFilter, FaPlus } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { SelectCmp } from '../../../../../../components/SelectCmp'
import { IModel } from '../../interfaces'
import { ComparativoChart } from '../../Components/ComparativoChart'
import { SwitchCmp } from '../../../../../../components/SwitchCmp'
import LoadingContainer from '../../../../../../components/LoadingContainer'
import { BarChart } from '../../Components/BarChart'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { ComparativoPDF } from '../../Components/ComparativoPDF'
import { iAtributo } from '../../../../../../interfaces'

interface iProps {
  Model: IModel
  isLoading: boolean
  tabAtiva: boolean
  onGetComparativo: (pesquisaId: string, demId: string) => void
}

export const TabComparativaPesquisa: React.FC<iProps> = ({ Model, onGetComparativo, isLoading, tabAtiva }) => {
  const [GrupoSelecionado, setGrupoSelecionado] = useState('')
  const [CompId, setCompId] = useState(Model.pesquisaAnterior !== null ? Model.pesquisaAnterior?.id : '')
  const [TodosAtributos, setTodosAtributos] = useState(true)
  const [LoadingAfetam, setLoadingAfetam] = useState(false)
  const [GraficoMediaPerguntas, setGraficoMediaPerguntas] = useState<string>('')
  const [GraficosgraficosPerguntasAtributo, setGraficosgraficosPerguntasAtributo] = useState<Array<{ avatar: string, grafico: string, nome: string }>>([])

  function GetGraficoMediaAtributoPergunta(): any {
    const arr: Array<{ avatar: string, grafico: string, nome: string }> = []
    return Model.comparativoPerguntasLista?.map((e, i) => {
      return (
        <Flex pos={'absolute'} top={-1000 * (i + 1)} left={-1000 * (i + 1)} width={'1000px'}>
          <ComparativoChart
            id={`mediaPerguntas-comparativo-comparada-pdf-${e[0].id}-${i}`}
            dados={e}
            legendas={['Questões que afetaram o engajamento', 'Média das questões - Pesquisa comparada', 'Média dasquestões - Pesquisa atual']}
            questoesAfetam
            onSetGrafico={(str) => { arr.push({ avatar: e[0].avatar ?? '', nome: e[0].titulo, grafico: str }); if (arr.length === Model.comparativoPerguntasLista?.length) { setGraficosgraficosPerguntasAtributo(arr) } }}
          />
        </Flex>
      )
    })
  }

  function GetAtributos(): iAtributo[] {
    const response: iAtributo[] = []
    if (!Model.comparativoAtributos) return []
    Model.comparativoAtributos[0].labels?.forEach(e => {
      const atributo = Model.atributos?.find(a => a.nome === e)
      if (atributo) response.push(atributo)
    })

    return response
  }

  return (
    <LoadingContainer linhas={22} loading={isLoading}>
      {tabAtiva && <Flex justifyContent={'space-between'} alignItems={'end'} mb={'2rem'}>
        {Model.comparativoAtributos && <Flex pos={'absolute'} left={-199999} width={'1000px'}>
          <ComparativoChart
            id={`pdf-mediaAtributos-comparativo-comparada-${Model.comparativoAtributos[0].id}`}
            dados={[Model.comparativoAtributos[1], Model.comparativoAtributos[0]]}
            legendas={['Atributos que afetaram o engajamento', 'Média de engajamento da pesquisa comparada', 'Média de engajamento da pesquisa atual', 'Média dos atributos - Pesquisa comparada', 'Média dos atributos - Pesquisa atual']}
            colorsAlternative={['rgb(3, 112, 21)', 'rgb(56, 70, 176)', 'rgb(56, 99, 176)', '#7bbde3', '#da84d5']}
            questoesAfetam
            labelFormat
            atributosComparativo
            onSetGrafico={setGraficoMediaPerguntas}
          />
        </Flex>}
        {GetGraficoMediaAtributoPergunta()}
        <Flex flexDir={'column'}>
          <Text>Pesquisa atual: {Model.pesquisa.descricao}</Text>
          <Flex gap={'1.25rem'} alignItems={'end'} mt={'1rem'}>
            <SelectCmp title='Pesquisa a ser comparada' value={CompId} OnChange={setCompId} width={'18rem'}>
              {Model.pesquisaAnterior !== null
                ? Model.pesquisasComparativo.map(e => {
                  return <option value={e?.id}>{e?.descricao}</option>
                })
                : <option value={''}>-</option>}
              {}
            </SelectCmp>
            <SelectCmp title='Filtro por demografia' value={GrupoSelecionado} OnChange={setGrupoSelecionado} width={'18rem'}>
              <option value={''}>Todas</option>
              {Model.demografiasFiltroComparativo?.map(e => {
                return (
                  <option value={e.itemDemografiaId}>{e.pergunta} - {e.nome}</option>
                )
              })}
            </SelectCmp>
            <ButtonCmp leftIcon={<FaFilter/>} onClick={() => onGetComparativo(CompId ?? '', GrupoSelecionado)} VarColor={'Green2'}>Buscar</ButtonCmp>
          </Flex>
        </Flex>
        <PDFDownloadLink
          document={
            <ComparativoPDF
              model={Model}
              atributos={GetAtributos()}
              graficosPerguntasAtributo={GraficosgraficosPerguntasAtributo}
              graficoMediaPerguntas={GraficoMediaPerguntas}
            />
          }
          fileName={'exportar_relatorio_comparativoPec_pec.pdf'}
        >
          {({ loading }) =>
            (loading || GraficoMediaPerguntas === '' || GraficosgraficosPerguntasAtributo.length !== Model.comparativoPerguntasLista?.length)
              ? (
                <ButtonCmp VarColor='Green2' leftIcon={<FaFilePdf/>} w={'19rem'} isLoading>Relatório PDF</ButtonCmp>
                )
              : <ButtonCmp VarColor='Green2' leftIcon={<FaFilePdf/>} w={'19rem'}>Relatório PDF</ButtonCmp>

          }
        </PDFDownloadLink>
      </Flex>}
      {Model.comparativoAtributos && <Flex flexDir={'column'} gap={'.75rem'}>
        <Divider borderColor={'var(--AzulOpaco)'} my={'1rem'} opacity={'1'}/>
        <Flex gap={'.75rem'} alignItems={'center'} pl={'1rem'}>
          <FaChartPie size={28} color={'var(--Azul)'}/>
          <Text fontWeight={800} fontSize={'26px'}>Média dos atributos em comum</Text>
        </Flex>
        <Flex w={'100%'} h={'450px'}>
          <ComparativoChart
            id={`mediaAtributos-comparativo-comparada-${Model.comparativoAtributos[0].id}`}
            dados={[Model.comparativoAtributos[1], Model.comparativoAtributos[0]]}
            legendas={['Atributos que afetaram o engajamento', 'Média de engajamento da pesquisa comparada', 'Média de engajamento da pesquisa atual', 'Média dos atributos - Pesquisa comparada', 'Média dos atributos - Pesquisa atual']}
            colorsAlternative={['rgb(3, 112, 21)', 'rgb(56, 70, 176)', 'rgb(142, 174, 232)', '#7bbde3', '#da84d5']}
            questoesAfetam
            labelFormat
            atributosComparativo
          />
        </Flex>
      </Flex>}
      {Model && <Flex flexDir={'column'} gap={'1.325rem'}>
        {<Divider borderColor={'var(--AzulOpaco)'} my={'2rem'} opacity={'1'}/>}
        <Flex gap={'.75rem'} alignItems={'center'} justifyContent={'space-between'}>
          <Flex flexDir={'column'} w={'50%'} gap={'.325rem'}>
            <Flex gap={'.75rem'} alignItems='center'>
              <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
                <FaChartBar fontSize={'2rem'} color={'var(--Azul)'}/>
              </Flex>
              <h3>Média das questões por atributo</h3>
            </Flex>
            <Text>Você pode utilizar o filtro ao lado para visualizar apenas os atributos que contém questões que afetam o engajamento.</Text>
          </Flex>
          {Model.graficoDispersaoAfetamEngajamento.serie && Model.graficoDispersaoAfetamEngajamento.serie?.length > 0 && <SwitchCmp
            OnChange={(e) => {
              setTodosAtributos(e)
              setLoadingAfetam(true)
              setTimeout(() => {
                setLoadingAfetam(false)
              }, 1000)
            }}
            value={TodosAtributos}
            legendaEsquerda={'Atributos que afetam o engajamento'}
            legendaDireita={'Todos os atributos'}
          />}
        </Flex>
        <LoadingContainer linhas={32} loading={LoadingAfetam}>
          {Model.comparativoPerguntasLista?.filter(e => {
            if (!TodosAtributos) {
              if (e.some(a => a.afetaEngajamento.some(a => a))) {
                return e
              } else {
                return null
              }
            } else {
              return e
            }
          }).map((e, i) => {
            return (
              <Flex flexDir={'column'} gap={'.75rem'}>
                <Flex gap={'1.25rem'} alignItems={'center'}>
                  <Flex w={'6rem'} h={'6rem'} borderRadius={'50%'} alignItems={'center'} justifyContent={'center'} p={'.325rem'} border={'2px dashed var(--c6)'}>
                    <Img w={'5.25rem'} h={'5.25rem'} borderRadius={'50%'} src={e[0].avatar}/>
                  </Flex>
                  <Text fontWeight={800} fontSize={'26px'}>{e[0].titulo}</Text>
                </Flex>
                <Flex w={'100%'} h={'450px'}>
                  <ComparativoChart
                    id={`mediaPerguntas-comparativo-comparada-${e[0].id}-${i}`}
                    dados={e}
                    legendas={['Questões que afetaram o engajamento', 'Média das questões - Pesquisa comparada', 'Média das questões - Pesquisa atual']}
                    questoesAfetam
                  />
                </Flex>
              </Flex>
            )
          })}
          {Model.comparativoPerguntasLista?.filter(e => {
            if (!TodosAtributos) {
              if (e.some(a => a.afetaEngajamento.some(a => a))) {
                return e
              } else {
                return null
              }
            } else {
              return e
            }
          }).length === 0 && <Flex p={'3rem'} fontWeight={700} fontSize={'16px'} justifyContent={'center'} textAlign={'center'}>Não há nenhum atributo neste filtro</Flex>}
        </LoadingContainer>
      </Flex>}
      <Divider borderColor={'var(--AzulOpaco)'} my={'2rem'} opacity={'1'}/>
      <Flex gap={'.25rem'} alignItems={'center'} flexDir={'column'} maxW={'66rem'} margin={'0 auto'} justifyContent={'center'}>
        <h3 style={{ textAlign: 'center' }}>Agora você pode adicionar planos de ação para trabalhar as questões apontadas em sua pesquisa de engajamento!</h3>
        <span style={{ textAlign: 'center' }}>Recomendamos que foque principalmente nas questões que afetam o engajamento na sua organização, ou seja, as questões que os respondentes realmente consideram importantes.</span>
        <ButtonCmp leftIcon={<FaPlus/>} VarColor='Green2' w={'12rem'} my={'.75rem'} onClick={() => { window.location.href = `${process.env.REACT_APP_URL_SIGAH}/Home/Plano` }}>Adicionar plano de ação</ButtonCmp>
      </Flex>

    </LoadingContainer>
  )
}
