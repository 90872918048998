import styled from 'styled-components'
import { Column, Flex } from '../../../styles/styledGlobal'

export const ButtonMenu = styled.div`
margin: 1rem 0rem;
gap: 1.5rem;
width: 100%;
display: flex;
justify-content: space-between;
`

export const GreyBox = styled(Column)<{margin?: string}>`
padding: .5rem 1rem;
margin: ${props => props.margin};
border:0.063rem solid var(--AzulOpaco);
border-radius: 0.25rem;

span{
    font-weight: 700;
    font-size: 1rem;
    color: var(--Azul);
    text-align: center;
}
`

export const PulseContainer = styled(Flex)`
align-items: center;
img{
    width: 4rem;
    height: 5.552rem;
    margin-right: 2rem;
}

strong,h3{
    color:#203864;
}

border-right:0.063rem solid var(--c4);
padding-right: 1rem;
margin-right: 1rem;
`

export const StyledSpan = styled.span<{margin?: string}>`
margin:${props => props.margin};
font-weight: 700;
font-size: 1rem;
color:var(--${props => props.color});
`

export const ColumnMedia = styled(Column)`
h4{
    margin-bottom: 1rem;
    color: #203864;
}
`

export const AtribGridContainer = styled.div<{ gridLength: number }>`
    display: grid;
    grid-template-columns: repeat(${props => props.gridLength > 7 ? 4 : props.gridLength},1fr);
    gap: 2rem 2rem;
`

export const ColumnEngajamento = styled(Column)`
justify-content: center;
align-items: center;
width: 11rem;
margin-right: 2rem;

div{
    width: 6rem;
    height: 6rem;
    border-radius: 2.594rem;
}

`

export const FlexBetween = styled(Flex)`
justify-content: space-between;
align-items: center;
`

export const HeadAtributoContainer = styled(FlexBetween)`
width: 100%;
margin-bottom: 2rem;
`
