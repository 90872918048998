/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Box, Flex, Img, Radio, RadioGroup, Text, useToast, Stack } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { FaChartPie, FaCheckCircle, FaShare } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import LoadingContainer from '../../../../components/LoadingContainer'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { iDemografiaPEC, iQuestao, iRespostaEscala } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { FormatarMensagemEditorDeTexto, useQuery } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import {
  Container,
  HeaderContainer,
  SkillAnswer,
  SkillQuestion,
  ComentarioContainer
} from './styles'

interface iResposta {
  questaoId: string
  respostaId?: string
  nota?: number
  resposta?: string
}

interface iFormQuestao extends iQuestao {
  isError: boolean
  observacao?: string
  avatar: string
  atributoId: string
  demografiaId?: string
  perguntaId?: string
  pesoAtrelada?: number
  perguntaAtreladaId?: string
}

export const RespostaPesquisaPec: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()
  const refs = useRef<any>([])
  const { pesquisaId, tokenId } = useParams<{ pesquisaId?: string, tokenId?: string }>()
  const { pathname } = useLocation()

  const Notas = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const query = useQuery()
  const quiosque = query.get('m')
  const [Questoes, setQuestoes] = useState<iFormQuestao[]>([])
  const [Errors, setErrors] = useState<string[]>([])
  const [TxtEncerramento, setTxtEncerramento] = useState<string>()
  const [Demografias, setDemografias] = useState<iDemografiaPEC[]>([])
  const [Respostas, setRespostas] = useState<iResposta[]>([])
  const [NomePesquisa, setNomePesquisa] = useState('')
  const [isRespondido, setisRespondido] = useState(false)
  const [IsLoading, setIsLoading] = useState(false)
  const [LogoEmpresa, setLogoEmpresa] = useState<string>('')
  const [EmpresaId, setEmpresaId] = useState<string>('')
  const [EscalaRespostas, setEscalaRespostas] = useState<iRespostaEscala[]>([])

  function getFormulario(): void {
    setIsLoading(true)
    pathname.includes('Previsualizar')
      ? appApi.get(`ResponderPec/${pesquisaId}/Previsualizar`)
        .then(resp => {
          setQuestoes(resp.data.questoes)
          setEscalaRespostas(resp.data.respostas)
          setNomePesquisa(resp.data.nomePesquisa)
          setLogoEmpresa(resp.data.logoEmpresa)
          setDemografias(resp.data.demografias)
          setTxtEncerramento(resp.data.txtEncerramento)
          setEmpresaId(resp.data.empresaId)
        }).catch(() => setisRespondido(true))
        .finally(() => setIsLoading(false))
      : appApi.get(`ResponderPec/${tokenId}`)
        .then(resp => {
          setQuestoes(resp.data.questoes)
          setEscalaRespostas(resp.data.respostas)
          setNomePesquisa(resp.data.nomePesquisa)
          setLogoEmpresa(resp.data.logoEmpresa)
          setDemografias(resp.data.demografias)
          setTxtEncerramento(resp.data.txtEncerramento)
          setEmpresaId(resp.data.empresaId)
        }).catch(() => setisRespondido(true))
        .finally(() => setIsLoading(false))
  }

  function UpdateResposta(questaoId: string, respostaId?: string, nota?: number, questoesExcluir?: iFormQuestao[]): void {
    const respostas = [...Respostas.filter(e => !questoesExcluir?.some(a => a.perguntaId === e.questaoId))]
    const pos = respostas.findIndex(e => e.questaoId === questaoId)
    if (pos !== -1) {
      if (respostaId) respostas[pos].respostaId = respostaId
      if (nota) respostas[pos].nota = nota
      setRespostas(respostas)
    }
  }

  function UpdateRespostaDiscursiva(questaoId: string, texto: string): void {
    const pos = Respostas.findIndex(e => e.questaoId === questaoId)
    const respostas = [...Respostas]
    if (pos !== -1) {
      respostas[pos].resposta = texto
      setRespostas(respostas)
    } else {
      setRespostas([...Respostas, { questaoId: questaoId, resposta: texto }])
    }
  }

  function GetIdsAtreladas(questaoId: string, respId?: string): string[] {
    const response: string[] = []

    const questao = Questoes.find(e => e.perguntaId === questaoId)
    const peso = EscalaRespostas.find(e => e.id === respId)?.peso
    let questaoAtrelada = Questoes.find(e => e.perguntaId === questao?.perguntaAtreladaId)

    if (peso === -1 || peso === 0 || (questao?.pesoAtrelada && peso && (peso <= questao?.pesoAtrelada))) return response

    while (questaoAtrelada) {
      response.push(questaoAtrelada.perguntaId ?? '')
      questaoAtrelada = Questoes.find(e => e.perguntaId === questaoAtrelada?.perguntaAtreladaId)
    }

    return response
  }

  function SelResposta(questaoId: string, resposId?: string, nota?: number): void {
    const demografia = Demografias.find(e => e.id === questaoId)
    const itemDemografia = Demografias.find(e => e.demografias?.some(a => a.id === resposId))?.demografias?.find(e => e.id === resposId)
    const idsAteladas = GetIdsAtreladas(questaoId, resposId)
    let questoesParaExcluir: iFormQuestao[] = []
    if (demografia && itemDemografia) {
      questoesParaExcluir = Questoes.filter(e => e.demografiaId !== itemDemografia.id && demografia.demografias?.some(a => e.demografiaId === a.id))
    } else if (idsAteladas.length > 0) {
      questoesParaExcluir = Questoes.filter(e => idsAteladas.includes(e.perguntaId ?? ''))
    }

    if (!isSelectQuestao(questaoId)) {
      setRespostas(oldArray => [...oldArray, { questaoId: questaoId, respostaId: resposId, nota: nota }])
    } else {
      if (!isSelect(questaoId, resposId, nota)) {
        UpdateResposta(questaoId, resposId, nota, questoesParaExcluir)
      }
    }
  }

  function AddErrorStats(questoesId: string[]): void {
    const quests = [...Questoes].map((e) => {
      return { ...e, isError: questoesId.includes(e.id) }
    }).sort((a, b) => { return a.tipo - b.tipo })
    if (quests.some(e => e.isError)) {
      refs.current[quests.findIndex(e => e.isError)]?.focus()
    }
    setQuestoes([...quests])
  }

  const isSelect = (perguntaId: string, resposId?: string, nota?: number): boolean => Respostas.find(e => e.questaoId === perguntaId && (e.respostaId === resposId && e.nota === nota)) !== undefined
  const isSelectQuestao = (perguntaId: string): boolean => Respostas.find(e => e.questaoId === perguntaId) !== undefined

  function postForm(): void {
    const semRespostaMarcada = Questoes.filter(e => !Respostas.find(r => r.questaoId === e.perguntaId))

    const naoRespondidas: iFormQuestao[] = []
    semRespostaMarcada.forEach(quest => {
      const questaoAtrelada = Questoes.find(e => e.perguntaAtreladaId === quest.perguntaId)
      if (questaoAtrelada) {
        const resp = Respostas.find(a => a.questaoId === questaoAtrelada.perguntaId)
        if (resp) {
          const peso = EscalaRespostas.find(a => a.id === resp.respostaId)
          if (!peso || (peso.peso <= (questaoAtrelada.pesoAtrelada ?? 0) && peso.peso !== -1)) {
            naoRespondidas.push(quest)
          }
        }
      } else if (quest.demografiaId) {
        const demografia = Demografias.find(e => e.demografias?.some(a => a.id === quest.demografiaId))?.demografias?.find(e => e.id === quest.demografiaId)
        if (demografia) {
          const demoEscolhida = Respostas.find(e => e.respostaId === demografia.id)
          if (demoEscolhida) {
            naoRespondidas.push(quest)
          }
        }
      } else {
        naoRespondidas.push(quest)
      }
    })
    // if (Questoes.some(e => e.tipo === 1)) {
    //   form.respostasDiscursivas = Questoes.filter(e => e.tipo === 1).map((e) => {
    //     return {
    //       questaoId: e.id,
    //       observacao: e.observacao
    //     } as iRespostaDiscursiva
    //   })
    //   const obrigatorios = form.respostasDiscursivas.filter(e => Obrigatorias.find(r => r === e.questaoId))
    //   if (obrigatorios.length > 0 && !obrigatorios.every(e => e.observacao !== undefined && e.observacao.length >= 3)) {
    //     toast({
    //       title: obterTraduzido('Algumas questões discursivas obrigatórias não foram preenchidas com no mínimo 3 caracteres ',
    //         'Some mandatory discursive questions were not filled in with at least 3 characters',
    //         'Algunas preguntas discursivas obligatorias no se completaron con al menos 3 caracteres'),
    //       status: 'error',
    //       isClosable: false,
    //       position: 'top-right',
    //       duration: 4000
    //     })
    //   }
    // }
    if (naoRespondidas.length > 0) {
      setErrors(naoRespondidas.map(e => e.id))
      toast({
        title: 'Alguns campos não foram preenchidos, revise o formulário e veja se possui campos em vermelho',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      AddErrorStats(naoRespondidas.map((e) => {
        return e.id
      }))
    } else {
      const form: iResposta[] = Respostas

      setIsLoading(true)
      appApi.post(`ResponderPec/${tokenId}/Responder?m=${quiosque ? 'q' : ''}`, form)
        .then((res) => {
          setisRespondido(true)
          if (quiosque) {
            setTimeout(() => {
              nav(res.data)
            }, 4000)
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  function ReturnQuestoes(e: iFormQuestao, i: number, isAtrelada?: boolean): any {
    let atrelada: iFormQuestao | undefined = Questoes.find(a => a.perguntaId === e.perguntaAtreladaId)
    const resp = Respostas.find(a => a.questaoId === e.perguntaId)
    if (resp) {
      const peso = EscalaRespostas.find(a => a.id === resp.respostaId)
      if (!peso || (peso.peso > (e.pesoAtrelada ?? 0) || peso.peso === -1)) {
        atrelada = undefined
      }
    } else {
      atrelada = undefined
    }

    if (e.atributoId === 'ab2d00c8-67db-4477-9460-cf440a6964cb' || e.atributoId === '4fd26649-b408-478d-b4a7-c797163f1a7a' || e.atributoId === 'a92f6984-e374-4bb5-a319-69ac12c3804f') return

    return (
    <Flex flexDir={'column'} gap={'1rem'} w={'full'}>
      <Flex flexDir={'column'} key={e.id} tabIndex={0} ref={(el) => (refs.current[i] = el)}>
        <SkillQuestion>
          <div>
            <Flex gap={'.5rem'} alignItems={'center !important'} py={'.25rem'}>
              <Flex gap={'.5rem'} alignItems={'center !important'} justifyContent={'center'}>
                {isAtrelada && <FaShare color='var(--Azul)'/>}
              </Flex>
              <Flex flexDirection={'column'} fontSize={'16px'} w={'100%'}>
                <Flex alignItems={'center'} gap={'6px'}>
                  <Text fontSize={'16px'}>{e.enunciado}</Text>
                  {Errors.some(a => a === e.id) && !Respostas.some(a => a.questaoId === e.perguntaId) && <Flex fontSize={'.813rem'} color={'var(--Red)'} fontWeight={'700'}>* Resposta pendente</Flex>}
                </Flex>
              </Flex>
            </Flex>
          </div>
          {
            EscalaRespostas.map((r: iRespostaEscala, i2) => {
              return (
                <SkillAnswer
                  key={i2}
                  type="button"
                  isSelected={isSelect(e.perguntaId ?? '', r.id)}
                  onClick={() => SelResposta(e.perguntaId ?? '', r.id)}
                >
                  <div>
                    <FaCheckCircle />
                  </div>
                  {EmpresaId === 'd36e2a35-becf-49f2-9314-b0d843e337d5' && <Flex as={'span'} alignItems={'center'} bg={'var(--c1)'} borderRadius={'50%'} justifyContent={'center'} boxSize={'fit-content'} w={'35px'} mx='6px' h={'37px'}>
                    <Img width={'50px'} mt={'3px'} src={r.urlAvatar}/>
                  </Flex>}
                  <span>{r.resposta}</span>
                </SkillAnswer>
              )
            })
          }
          {Errors.some(a => a === e.id) && !Respostas.some(a => a.questaoId === e.perguntaId) && <Flex justifyContent={'center'} fontSize={'.875rem'} color={'var(--Red)'} mt={'.5rem'} fontWeight={'700'}>Selecione uma alternativa para essa questão!</Flex>}
        </SkillQuestion>
      </Flex>
      {atrelada && <Flex pl={'2.5rem'} minW={'100%'}>
        {ReturnQuestoes(atrelada, i, true)}
      </Flex>}
    </Flex>)
  }

  useEffect(() => {
    getFormulario()
  }, [])

  return (
    <Body>
      <LoadingContainer linhas={25} loading={IsLoading}>
        <WhiteContainer padding='0'>
          <HeaderContainer>
            <Flex flexDir={'column'}>
              <h1>{NomePesquisa}</h1>
            </Flex>
            <Img alt='Logo da empresa' src={LogoEmpresa ?? 'https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png'} />
          </HeaderContainer>
          <Box bg='var(--DegradeAzul)' className={'gradient-blue'} h={'3px'} w={'full'}></Box>
          {
            (!isRespondido && Questoes.length > 0) && (
              <Container>
                {Demografias.map(e => {
                  return (
                    <Flex flexDir={'column'} key={e.id}>
                        <SkillQuestion>
                          <div>
                            <Flex gap={'.5rem'} alignItems={'center !important'} py={'.25rem'}>
                              <Flex w={'2rem'} h={'2rem'} alignItems={'center'}>
                                <FaChartPie size={'2rem'} color={'var(--Azul)'}/>
                              </Flex>
                              <Flex flexDirection={'column'} w={'100%'} mt={'0 !important'} justifyContent={'center'}>
                                <Text color={'var(--Gray3)'} fontSize={'13px'}>Demografia:</Text>
                                <Flex alignItems={'center'} gap={'6px'}>
                                  <Text fontSize={'16px'}>{e.pergunta}</Text>
                                  {Errors.some(a => a === e.id) && !Respostas.some(a => a.questaoId === e.id) && <Flex fontSize={'.813rem'} color={'var(--Red)'} fontWeight={'700'}>* Resposta pendente</Flex>}
                                </Flex>
                              </Flex>
                            </Flex>
                          </div>
                          {
                            e.demografias?.map((r, i2) => {
                              return (
                                <SkillAnswer
                                  key={i2}
                                  type="button"
                                  isSelected={isSelect(e.id, r.id)}
                                  onClick={() => SelResposta(e.id, r.id)}
                                >
                                  <div>
                                    <FaCheckCircle />
                                  </div>
                                  <span>{r.nome}</span>
                                </SkillAnswer>
                              )
                            })
                          }
                        <Flex justifyContent={'end'} alignItems={'center'} pt={'1rem'}>
                          <ButtonCmp onClick={() => setRespostas(Respostas.filter(rp => rp.questaoId !== e.id))} VarColor={'Rosa'}>Limpar resposta</ButtonCmp>
                        </Flex>
                        {Errors.some(a => a === e.id) && !Respostas.some(a => a.questaoId === e.id) && <Flex justifyContent={'center'} fontSize={'.875rem'} color={'var(--Red)'} mt={'.5rem'} fontWeight={'700'}>Selecione uma alternativa para essa questão!</Flex>}
                        </SkillQuestion>
                      </Flex>
                  )
                })}
                {
                  Questoes.filter(e => {
                    if (Questoes.some(a => a.perguntaAtreladaId === e.perguntaId)) {
                      return null
                    } else if (e.demografiaId === null) {
                      return e
                    } else if (Respostas.some(a => a.respostaId === e.demografiaId)) {
                      return e
                    } else {
                      return null
                    }
                  }).map((e, i) => {
                    return (
                      ReturnQuestoes(e, i)
                    )
                  })
                }

                {
                  Questoes.filter(e => {
                    if (Questoes.some(a => a.perguntaAtreladaId === e.id)) {
                      return null
                    } else if (e.demografiaId === null) {
                      return e
                    } else if (Respostas.some(a => a.respostaId === e.demografiaId)) {
                      return e
                    } else {
                      return null
                    }
                  })?.filter(e => e.atributoId === '4fd26649-b408-478d-b4a7-c797163f1a7a' || e.atributoId === 'a92f6984-e374-4bb5-a319-69ac12c3804f').map((e, i) => {
                    return (
                      <Flex flexDir={'column'} key={e.id}>
                        <SkillQuestion>
                          <div>
                            <Flex gap={'.5rem'} alignItems={'center !important'} py={'.25rem'}>
                              <Flex flexDirection={'column'} fontSize={'16px'} w={'100%'}>
                                <Flex alignItems={'center'} gap={'6px'}>
                                  <Text fontSize={'16px'}>{e.enunciado}</Text>
                                  {Errors.some(a => a === e.id) && !Respostas.some(a => a.questaoId === e.perguntaId) && <Flex fontSize={'.813rem'} color={'var(--Red)'} fontWeight={'700'}>* Resposta pendente</Flex>}
                                </Flex>
                              </Flex>
                            </Flex>
                          </div>
                          <RadioGroup size={'md'} value={Respostas.find(a => a.questaoId === e.perguntaId)?.nota}>
                            <Stack direction={'row'} gap={'1rem'} px={'1.5rem'}>
                              {
                                Notas.map((r, i2) => {
                                  return (
                                    <Flex
                                      flexDir={'column'}
                                      key={i2}
                                      cursor={'pointer'}
                                      alignItems={'center !important'}
                                      justifyContent='center'
                                      onClick={() => SelResposta(e.perguntaId ?? '', undefined, r)}
                                    >
                                      <Radio style={{ cursor: 'pointer' }} value={r}/>
                                      <Text color={'black'} fontWeight={700} fontSize={'18px'}>{r}</Text>
                                    </Flex>
                                  )
                                })
                              }
                            </Stack>
                          </RadioGroup>
                          {Errors.some(a => a === e.id) && !Respostas.some(a => a.questaoId === e.perguntaId) && <Flex justifyContent={'center'} fontSize={'.875rem'} color={'var(--Red)'} mt={'.5rem'} fontWeight={'700'}>Selecione uma alternativa para essa questão!</Flex>}
                        </SkillQuestion>
                      </Flex>
                    )
                  })
                }

                {
                  Questoes.filter(e => e.atributoId === 'ab2d00c8-67db-4477-9460-cf440a6964cb').map((e, i) => {
                    return (
                      <Flex flexDir={'column'} key={e.id}>
                        <SkillQuestion>
                          <div>
                            <Flex gap={'.5rem'} alignItems={'center !important'} py={'.25rem'}>
                              <Flex flexDirection={'column'} fontSize={'16px'} w={'100%'}>
                                <Flex alignItems={'center'} gap={'6px'}>
                                  <Text fontSize={'16px'}>{e.enunciado}</Text>
                                  {Errors.some(a => a === e.id) && !Respostas.some(a => a.questaoId === e.perguntaId) && <Flex fontSize={'.813rem'} color={'var(--Red)'} fontWeight={'700'}>* Resposta pendente</Flex>}
                                </Flex>
                              </Flex>
                            </Flex>
                          </div>
                          <ComentarioContainer key={e.id}>
                            <textarea
                              placeholder={'Digite um comentário sobre o avaliado'}
                              onChange={(r) => UpdateRespostaDiscursiva(e.perguntaId ?? '', r.target.value)}
                              minLength={3}
                              value={Respostas.find(a => a.questaoId === e.perguntaId)?.resposta}
                            />
                          </ComentarioContainer>
                          {Errors.some(a => a === e.id) && !Respostas.some(a => a.questaoId === e.perguntaId) && <Flex justifyContent={'center'} fontSize={'.875rem'} color={'var(--Red)'} mt={'.5rem'} fontWeight={'700'}>O comentário deve ter no mínimo 5 caracteres!</Flex>}
                        </SkillQuestion>
                      </Flex>
                    )
                  })
                }
                {!pathname.includes('Previsualizar') &&
                  <Flex justifyContent={'center'} margin={'1rem 0rem'}>
                    <ButtonCmp onClick={postForm} h={'2.125rem'} fontSize={'1rem'} VarColor='Green2'>Enviar resposta</ButtonCmp>
                  </Flex>}
                {pathname.includes('Previsualizar') &&
                  <Flex marginTop={'1rem'} justifyContent={'flex-end'}>
                    <ButtonCmp
                      h={'2.125rem'}
                      fontSize={'1rem'}
                      VarColor='Rosa'
                      onClick={() => nav(-1)}
                    >Finalizar pré-visualização</ButtonCmp>
                  </Flex>
                }
              </Container>
            )
          }

          {
            (isRespondido) && (
              <Flex background={'white'} height={'100%'}>
                <Flex padding={'3rem 3rem'} flexDirection={'column'}>
                  <h3 dangerouslySetInnerHTML={{
                    __html: FormatarMensagemEditorDeTexto(TxtEncerramento && TxtEncerramento !== '' ? TxtEncerramento : 'Obrigado pela participação!')
                  }}></h3>
                  <Text
                    fontWeight={'400'}
                    style={{
                      color: 'var(--Gray3)',
                      marginTop: '.25rem'
                    }}>
                    Respostas enviadas com sucesso!</Text>
                  {
                    (localStorage.getItem('token')) && (
                      <Flex marginTop={'.75rem'}>
                        <ButtonCmp
                          h={'2.125rem'}
                          fontSize={'1rem'}
                          VarColor='Green2'
                          onClick={() => nav('/')}
                        >Voltar</ButtonCmp>
                      </Flex>
                    )
                  }
                </Flex>
              </Flex>
            )
          }
        </WhiteContainer>
      </LoadingContainer>
    </Body>
  )
}
