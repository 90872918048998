import styled from 'styled-components'

export const Container = styled.div`
background-color: white;
padding: 1rem;
height: 100%;
`

export const HeaderContainer = styled.div`
display: flex;
justify-content: space-between;
padding: 1.5rem 2rem 1rem 2rem;
background-color: white;
align-items: center;

img{
  max-height: 10rem;
  max-width: 10rem;
  object-fit: scale-down;
}
`

export const StyledInput = styled.input`
  font-size: 14px;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  border: 1px solid #ccc;
  outline: none;
  width: 14.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  }

  &:hover {
    border-color: #aaa;
  }
`
