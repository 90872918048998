/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useEffect } from 'react'
import ApexCharts from 'apexcharts'
import { GraficoPEC } from '../../interfaces'

interface iChartProps{
  id: string
  dados: GraficoPEC
}

export const PieChart: React.FC<iChartProps> = ({ id, dados }) => {
  function GerarGrafico(): void {
    const options = {
      series: dados.serie,
      labels: dados.labels,
      legend: {
        show: false
      },
      colors: ['rgba(0, 153, 255, 0.9)', 'rgba(255, 153, 0, 0.9)', 'rgba(255, 80, 80, 0.9)', 'rgba(0, 180, 90, 0.9)', 'rgba(255, 204, 0, 0.9)',
        'rgba(0, 255, 153, 0.9)', 'rgba(102, 255, 255, 0.9)', 'rgba(0, 153, 153, 0.9)', 'rgba(204, 255, 102, 0.9)', 'rgba(155, 179, 255, 0.9)',
        'rgba(255, 135, 135, 0.9)', 'rgba(255, 255, 51, 0.9)', 'rgba(178, 101, 255, 0.9)', 'rgba(255, 228, 201, 0.9)', 'rgba(255, 255, 153, 0.9)', 'rgba(0, 153, 255, 0.9)', 'rgba(255, 153, 0, 0.9)', 'rgba(255, 80, 80, 0.9)', 'rgba(0, 180, 90, 0.9)', 'rgba(255, 204, 0, 0.9)',
        'rgba(0, 255, 153, 0.9)', 'rgba(102, 255, 255, 0.9)', 'rgba(0, 153, 153, 0.9)', 'rgba(204, 255, 102, 0.9)', 'rgba(155, 179, 255, 0.9)',
        'rgba(255, 135, 135, 0.9)', 'rgba(255, 255, 51, 0.9)', 'rgba(178, 101, 255, 0.9)', 'rgba(255, 228, 201, 0.9)', 'rgba(255, 255, 153, 0.9)'],
      chart: {
        type: 'pie',
        height: 380,
        width: 380
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val: any) {
            return val
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val.toFixed(1) + '%'
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false
            }
          }
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }]
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [dados])

  return (
        <div id={id}></div>
  )
}
