import { Flex, FormControl, FormLabel, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { MdClose, MdSave } from 'react-icons/md'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../../components/InputCmp'
import { ModalBase } from '../../../../../../components/ModalBase'
import { iDemografiaPEC, ItemDemografia } from '../../../../../../interfaces'

interface iModalProps {
  isOpen: boolean
  Demografia?: iDemografiaPEC
  isSelecionado: boolean
  onClose: () => void
  onUpdate: (form: iDemografiaPEC) => void
  onAlterSelecao: (id: string) => void
}
export const ModalUpdateDemografia: React.FC<iModalProps> = ({ isOpen, Demografia, isSelecionado, onClose, onUpdate, onAlterSelecao }) => {
  const [Demografias, setDemografias] = useState<ItemDemografia[]>([])
  const [Nome, setNome] = useState('')

  function UpdateDemografiasArray(index: number, Texto: string): void {
    const array = [...Demografias]
    array[index].nome = Texto
    setDemografias(array)
  }

  function DeleteItemArray(index: number): void {
    const dems: ItemDemografia[] = []
    Demografias.forEach((e, i) => {
      if (index !== i) { dems.push(e) }
    })
    setDemografias(dems)
  }

  useEffect(() => {
    if (Demografia !== undefined) {
      setDemografias(Demografia?.demografias ?? [])
      setNome(Demografia?.pergunta)
    }
  }, [Demografia])

  return (
        <ModalBase
            Titulo="Editando demografia (grupo para análise)"
            Subtitulo="Ao  adicionar ou remover grupos de demografia você pode demorar para obter dados para as análises"
            onClose={onClose}
            isOpen={isOpen}
            Width='60rem'
        >
            <Flex flexDirection={'column'} padding={'1rem'} borderBottom={'1px solid var(--c4)'}>
                <FormControl>
                    <InputCmp
                        label='Nome da demografia'
                        placeholder='Digite o nome'
                        width={'18rem'}
                        value={Nome}
                        OnChange={setNome}
                    />
                </FormControl>

                <FormControl mt={'.75rem'}>
                    <FormLabel pos={'relative'} mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'} wordBreak={'keep-all'}>Grupos</FormLabel>
                    <Flex display={'grid'} gap={'1rem 1rem'} alignItems='center'>
                        {
                           Demografias?.map((e, i) => {
                             return (
                                    <InputGroup key={i}>
                                        {
                                            (Demografias.length > 1) && (
                                                <InputRightElement
                                                    h={'1.925rem'}
                                                    children={<MdClose cursor={'pointer'}
                                                    onClick={() => DeleteItemArray(i)}
                                                    color='var(--Red)' />}
                                                />
                                            )
                                        }
                                        <Input
                                            placeholder={`Grupo ${i !== 0 ? i : ''}`}
                                            borderColor={'var(--Gray4)'}
                                            height={'1.925rem'}
                                            fontSize={'14px'}
                                            borderRadius='4px'
                                            value={e.nome}
                                            onChange={(e) => UpdateDemografiasArray(i, e.target.value)}
                                        />
                                    </InputGroup>
                             )
                           })
                        }

                    </Flex>
                </FormControl>

                <Flex marginTop={'1rem'}>
                    <ButtonCmp
                        VarColor='Green2'
                        leftIcon={<FaPlus />}
                        onClick={() => setDemografias(oldArray => [...oldArray, { nome: '', id: '' }])}
                    >Adicionar</ButtonCmp>
                </Flex>
            </Flex>
            <Flex justifyContent={'end'} padding={'1rem'}>
                <ButtonCmp
                    VarColor='Green2'
                    leftIcon={<MdSave />}
                    onClick={() => onUpdate({
                      id: Demografia?.id as string,
                      pergunta: Nome,
                      demografias: Demografias
                    })}
                >Salvar</ButtonCmp>
            </Flex>
        </ModalBase>
  )
}
