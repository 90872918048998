import { Box, FormLabel, Select, SelectProps } from '@chakra-ui/react'

interface iFormProps extends SelectProps {
  title?: string
  value?: string
  OnChange: (e: string) => void
  isInvalid?: boolean
  mb?: string
}

export const SelectCmp: React.FC<iFormProps> = ({ children, OnChange, title, value, w, h, width, isInvalid, mb }) => {
  return (
    <Box w={w} width={width} mb={mb}>
      {title && <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>{title}</FormLabel>}
      <Select isInvalid={isInvalid} onChange={(e) => OnChange(e.target.value)} value={value} bg={'white'} borderColor={'var(--Gray4)'} size='xs' h={h ?? '1.975rem'} fontSize={'.875rem'} w={width}>
        {children}
      </Select>
    </Box>
  )
}
