/* eslint-disable no-return-assign */
import { Menu, MenuButton, Button, Flex, Badge, AlertIcon, Alert, Link } from '@chakra-ui/react'
import { FaBriefcase, FaChalkboardTeacher, FaChevronDown, FaClipboardList, FaCog, FaColumns, FaComments, FaCopy, FaDoorOpen, FaHeadset, FaHome, FaIdCard, FaPaperPlane, FaPeopleArrows, FaPlane, FaPlusSquare, FaQuestionCircle, FaSitemap, FaStreetView, FaTachometerAlt, FaUserClock, FaUsersCog } from 'react-icons/fa'
import { BsCalendarCheck } from 'react-icons/bs'
import { MdLogout, MdMenu, MdPassword } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { StyledMenuItem } from './Components/StyledMenuItem'
import { Container, FlexIcon, LogoContainer } from './styles'

import LogoPec from '../../assets/PEC.svg'
import LogoPulse from '../../assets/Pulse.svg'
import LogoFO from '../../assets/FO.svg'
// import LogoTurnOver from '../../assets/TurnOver.svg'
import { urlSigah } from '../../services/appApi'
import { parseJwt } from '../../services/token'
import { StyledMenuList } from './Components/StyledMenuList'
import { useEffect } from 'react'
import { AutorizarAHPI, AutorizarPI, ObterToken, PropInvalida } from '../../Utils/Helper'
import { StyledMenuButton } from './Components/StyledMenuButton'
import { AiOutlineTable } from 'react-icons/ai'
import { GoGraph } from 'react-icons/go'

export const NavBar: React.FC = () => {
  const { Permissoes, permitirFuncao, isAuth, logOut, refreshToken, obterVersao, recarregarPermissoes, modoFree } = useAuth()
  const BlackList = ['/', 'RespostaPesquisa', 'RespostaPesquisaIndicativa', 'Abertura', 'RespostaNps', 'VisualizarImersao', 'EsqueciSenha', 'Preenchimento', 'Pi', 'pi']
  const { pathname } = useLocation()

  function NaoTemNavBar(): boolean {
    let temAcesso = true
    if (pathname === '/') { return true }
    pathname.split('/').forEach((e) => {
      const res = BlackList.find(r => r === e)
      if (res !== undefined) {
        temAcesso = false
      }
    })

    if (temAcesso) {
      return false
    }

    return true
  }

  if (NaoTemNavBar() || !isAuth()) {
    return null
  }

  function GestoresViaCerta(): boolean {
    if (parseJwt().eid !== 'faee5a9b-55ff-4270-9f18-8e3b7139dd5d') return true

    const cid = parseJwt().cid
    if (cid === '4ab4e58e-a58c-4081-ac1c-5220fdd9bff0' || cid === 'b33ffa78-cf8d-4a53-a2f2-e26e57384ef3' || cid === 'eebf2128-ce3e-47e9-b6d4-b66ad0a2ede0' || cid === '446ba5eb-ec47-4598-b788-06d45334207b' || cid === '91e8070c-aa4d-47b8-af3a-b84b8ae273d0') {
      return true
    }

    return false
  }

  const nav = useNavigate()
  const Route = `${urlSigah}/Home/ExtLogin/?token=${ObterToken()}`
  const redirectPage = (ct: string, at: string): any => window.location.href = `${Route}&ct=${ct}&at=${at}`

  useEffect(() => {
    obterVersao()
    refreshToken()
    if (Permissoes.permissoes.length === 0) {
      recarregarPermissoes()
    }
  }, [pathname])
  return (<>
    <Container>
      <Flex alignItems={'center'} gap={'.5rem'}>
        <LogoContainer>
          <img onClick={() => nav('/Colaborador')} src={localStorage.getItem('logo') === 'https://sigah.arquiteturahumana.com.br/css/logoBranco.png' || PropInvalida(localStorage.getItem('logo')) ? 'https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png' : localStorage.getItem('logo') ?? ''} />
          <hr />
        </LogoContainer>
        <Button
          px={'.75rem'}
          height={'1.925rem'}
          borderRadius='.25rem'
          transition='all 0.2s'
          _hover={{ bg: 'var(--Rosa)' }}
          _focus={{ bg: 'var(--Rosa)' }}
          bg={'none'}
          fontWeight={'600'}
          fontSize={'.85rem'}
          _active={{ bg: 'var(--Rosa)' }}
          onClick={() => nav('/Colaborador')}
        >
          Início
        </Button>

        {
          (Permissoes.jornada) && (
            <Menu>
              <StyledMenuButton rightIcon={<FaChevronDown style={{ marginLeft: '-4px' }} size={10}/>}>
                Jornada
              </StyledMenuButton>
              <StyledMenuList>
                {
                  ((parseJwt().adm === 'True') || permitirFuncao('Jornada', 'dash')) && (
                    <StyledMenuItem
                      onClick={() => nav('/Jornada/Dashboard')}
                    >
                      <FaColumns style={{ marginRight: '.4rem' }} />
                      Dashboard
                    </StyledMenuItem>
                  )
                }

                {(parseJwt().adm === 'True' || permitirFuncao('Jornada', 'trilha-onboard')) && (
                  <StyledMenuItem
                    onClick={() => nav('Jornada/Onboard?tipo=0')}
                  >
                    <FaUserClock style={{ marginRight: '.4rem' }} />
                    Trilha onboarding
                  </StyledMenuItem>
                )}

                {(parseJwt().adm === 'True' || permitirFuncao('Jornada', 'trilha-desenvolvimento')) && (
                  <StyledMenuItem
                    onClick={() => nav('Jornada/Onboard?tipo=1')}
                  >
                    <FaPlane style={{ marginRight: '.4rem' }} />
                    Trilha de desenvolvimento
                  </StyledMenuItem>
                )}

                {(parseJwt().adm === 'True' || permitirFuncao('Jornada', 'trilha-offboard')) && (
                  <StyledMenuItem
                    onClick={() => nav('Jornada/Onboard?tipo=3')}
                  >
                    <FaDoorOpen style={{ marginRight: '.4rem' }} />
                    Offboarding
                  </StyledMenuItem>
                )}

                {(parseJwt().adm === 'True' || permitirFuncao('Jornada', 'feedback-sah')) && (
                  <StyledMenuItem
                    onClick={() => nav('Jornada/Feedback/Dashboard/Individual')}
                  >
                    <FaComments style={{ marginRight: '.4rem' }} />
                    Feedback
                  </StyledMenuItem>
                )}

                {(parseJwt().eid === process.env.REACT_APP_AH_EMPRESA_ID || parseJwt().eid === process.env.REACT_APP_JOAO_EMPRESA_ID) && parseJwt().adm === 'True' &&
                  <StyledMenuItem
                    onClick={() => nav('Jornada/FeedbackPersonalizado/Dashboard')}
                  >
                    <FaComments style={{ marginRight: '.4rem' }} />
                    Feedback personalizado
                  </StyledMenuItem>
                }

                {
                  (parseJwt().adm === 'True' || permitirFuncao('Jornada', 'nova-reuniao-one')) && (
                    <StyledMenuItem
                      onClick={() => nav('Jornada/NovaReuniaoOne/Dashboard/Individual')}
                    >
                      <FaPeopleArrows style={{ marginRight: '.4rem' }} />
                      Reunião 1:1
                    </StyledMenuItem>
                  )
                }

                {
                  ((parseJwt().adm === 'True' || permitirFuncao('Jornada', '1on1')) && process.env.REACT_APP_VIACERTA_EMPRESA_ID === parseJwt().eid) && (
                    <StyledMenuItem
                      onClick={() => nav('Jornada/ReuniaoOne')}
                    >
                      <FaPeopleArrows style={{ marginRight: '.4rem' }} />
                      Reunião 1:1 (Obsoleto)
                    </StyledMenuItem>
                  )
                }

                {
                  (parseJwt().adm === 'True' || permitirFuncao('Jornada', 'pesquisa-percepcao')) && (
                    <StyledMenuItem
                      onClick={() => nav('Jornada/Percepcao/Gestao')}
                    >
                      <FaStreetView style={{ marginRight: '.4rem' }} />
                      Pesquisa de percepção
                    </StyledMenuItem>
                  )
                }

                {
                  (parseJwt().adm === 'True' || permitirFuncao('Jornada', 'pesquisa-interna')) && (
                    <StyledMenuItem
                      onClick={() => nav('/Jornada/PesquisaInterna/Gestao')}
                    >
                      <FaClipboardList style={{ marginRight: '.4rem' }} />
                      Pesquisa interna
                    </StyledMenuItem>
                  )
                }

                {
                  (parseJwt().adm === 'True' || permitirFuncao('Jornada', 'imersão-cultural')) && (
                    <StyledMenuItem
                      onClick={() => nav('/Jornada/Imersao')}
                    >
                      <FaChalkboardTeacher style={{ marginRight: '.4rem' }} />
                      Imersão cultural
                    </StyledMenuItem>
                  )
                }

                {
                  (parseJwt().adm === 'True' || permitirFuncao('Jornada', 'enps')) && (
                    <StyledMenuItem
                      onClick={() => nav('/Jornada/eNps')}
                    >
                      <FaTachometerAlt style={{ marginRight: '.4rem' }} />
                      eNPS
                    </StyledMenuItem>
                  )
                }

                {/* {
                                    (parseJwt().adm === 'True' || permitirFuncao('Jornada', 'turnover')) && (
                                        <StyledMenuItem
                                            onClick={() => nav('/Jornada/TurnOver')}
                                        >
                                            <img src={LogoTurnOver} style={{ marginRight: '.4rem', height: '1rem' }} />
                                            Turnover
                                        </StyledMenuItem>
                                    )
                                } */}

              </StyledMenuList>
            </Menu>
          )
        }

        {
          (!Permissoes.jornada) && (
            <Menu>
              <StyledMenuButton rightIcon={<FaChevronDown style={{ marginLeft: '-4px' }} size={10}/>}>
                Jornada
              </StyledMenuButton>
              <StyledMenuList>
                <StyledMenuItem>
                  <FaColumns style={{ marginRight: '.4rem' }} />
                  Módulo bloqueado
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaColumns style={{ marginRight: '.4rem' }} />
                  Dashboard
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaUserClock style={{ marginRight: '.4rem' }} />
                  Trilha onboarding
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaPlane style={{ marginRight: '.4rem' }} />
                  Trilha de desenvolvimento
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaDoorOpen style={{ marginRight: '.4rem' }} />
                  Offboarding
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaPeopleArrows style={{ marginRight: '.4rem' }} />
                  Reunião 1:1
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaStreetView style={{ marginRight: '.4rem' }} />
                  Pesquisa de percepção
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaClipboardList style={{ marginRight: '.4rem' }} />
                  Pesquisa interna
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaChalkboardTeacher style={{ marginRight: '.4rem' }} />
                  Imersão cultural
                </StyledMenuItem>
                <StyledMenuItem
                  disabled
                >
                  <FaTachometerAlt style={{ marginRight: '.4rem' }} />
                  eNPS
                </StyledMenuItem>
              </StyledMenuList>
            </Menu>
          )
        }

        {
          (Permissoes.desempenho) && (
            <Menu>
              <StyledMenuButton rightIcon={<FaChevronDown style={{ marginLeft: '-4px' }} size={10}/>}>
                Desempenho
              </StyledMenuButton>
              <StyledMenuList>
                {
                  ((parseJwt().adm === 'True') || permitirFuncao('Desempenho', 'dash')) && (
                    <StyledMenuItem
                      onClick={() => nav('/Desempenho/Dashboard')}
                    >
                      <FaColumns style={{ marginRight: '.4rem' }} />
                      Dashboard
                    </StyledMenuItem>
                  )
                }

                {
                  (parseJwt().adm === 'True') && (
                    <StyledMenuItem
                      onClick={() => nav('/Desempenho/AnaliseDesempenho/SelecionarModelo')}
                    >
                      <FaPlusSquare style={{ marginRight: '.4rem' }} />
                      Criar pesquisa de desempenho por competência
                    </StyledMenuItem>
                  )
                }

                {
                  (parseJwt().adm === 'True' || permitirFuncao('Desempenho', 'pesquisa-desempenho')) && (
                    <StyledMenuItem
                      onClick={() => nav('/Desempenho/AnaliseDesempenho/Gestao')}
                    >
                      <FaCopy style={{ marginRight: '.4rem' }} />
                      Gestão de pesquisas de desempenho por competência
                    </StyledMenuItem>
                  )
                }

                {
                  (parseJwt().adm === 'True') && (
                    <StyledMenuItem
                      onClick={() => nav('/Desempenho/Formularios/Gestao')}
                    >
                      <FaClipboardList style={{ marginRight: '.4rem' }} />
                      Gestão de formulários
                    </StyledMenuItem>
                  )
                }

                {
                  (parseJwt().adm === 'True' || permitirFuncao('Desempenho', '9box')) && (
                    <StyledMenuItem
                      onClick={() => nav('/Desempenho/9Box')}>
                      <AiOutlineTable style={{ marginRight: '.4rem' }} />
                      9Box
                    </StyledMenuItem>
                  )
                }

                {
                  (parseJwt().adm === 'True' || permitirFuncao('Desempenho', 'feedback-sah')) && (
                    <StyledMenuItem
                      onClick={() => nav('Desempenho/Feedback/Dashboard/Individual')}
                    >
                      <FaComments style={{ marginRight: '.4rem' }} />
                      Feedback
                    </StyledMenuItem>
                  )
                }

                {
                  (parseJwt().adm === 'True' || permitirFuncao('Desempenho', 'nova-reuniao-one')) && (
                    <StyledMenuItem
                      onClick={() => nav('Desempenho/NovaReuniaoOne/Dashboard/Individual')}
                    >
                      <FaPeopleArrows style={{ marginRight: '.4rem' }} />
                      Reunião 1:1
                    </StyledMenuItem>
                  )
                }

                {/* {
                  (parseJwt().adm === 'True' || permitirFuncao('Desempenho', '1on1')) && (
                    <StyledMenuItem
                      onClick={() => nav('Desempenho/ReuniaoOne')}
                    >
                      <FaPeopleArrows style={{ marginRight: '.4rem' }} />
                      Reunião 1:1
                    </StyledMenuItem>
                  )
                } */}

                {
                  (parseJwt().adm === 'True' || permitirFuncao('Desempenho', 'pdi')) && (
                    <StyledMenuItem
                      onClick={() => nav('/Desempenho/PDI/Dashboard/Individual')}
                    >
                      <BsCalendarCheck style={{ marginRight: '.4rem' }} />
                      PDI
                    </StyledMenuItem>
                  )
                }

                {
                  (parseJwt().adm === 'True' || permitirFuncao('Desempenho', 'imersao-cultural')) && (
                    <StyledMenuItem
                      onClick={() => nav('/Jornada/Imersao')}
                    >
                      <FaChalkboardTeacher style={{ marginRight: '.4rem' }} />
                      Imersão cultural
                    </StyledMenuItem>
                  )
                }

                {
                  (parseJwt().adm === 'True' || permitirFuncao('Desempenho', 'indicadores')) && (
                    <StyledMenuItem
                      onClick={() => nav('/Desempenho/Indicadores')}
                    >
                      <GoGraph style={{ marginRight: '.4rem' }} />
                      Indicadores
                    </StyledMenuItem>
                  )
                }
              </StyledMenuList>
            </Menu>
          )
        }

        {
          (!Permissoes.desempenho) && (
            <Menu>
              <StyledMenuButton rightIcon={<FaChevronDown style={{ marginLeft: '-4px' }} size={10}/>}>
                Desempenho
              </StyledMenuButton>
              <StyledMenuList>
                <StyledMenuItem>
                  <FaColumns style={{ marginRight: '.4rem' }} />
                  Módulo bloqueado
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaColumns style={{ marginRight: '.4rem' }} />
                  Dashboard
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaPlusSquare style={{ marginRight: '.4rem' }} />
                  Criar pesquisa de desempenho por competência
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaCopy style={{ marginRight: '.4rem' }} />
                  Gestão de pesquisas de desempenho por competência
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaClipboardList style={{ marginRight: '.4rem' }} />
                  Gestão de formulários
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaPeopleArrows style={{ marginRight: '.4rem' }} />
                  Reunião 1:1
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <BsCalendarCheck style={{ marginRight: '.4rem' }} />
                  PDI
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaChalkboardTeacher style={{ marginRight: '.4rem' }} />
                  Imersão cultural
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <GoGraph style={{ marginRight: '.4rem' }} />
                  Indicadores
                </StyledMenuItem>
              </StyledMenuList>
            </Menu>
          )
        }

        {((!GestoresViaCerta() && parseJwt()?.adm === 'False') || !Permissoes.engajamento) && (
          <Menu>
            <StyledMenuButton rightIcon={<FaChevronDown style={{ marginLeft: '-4px' }} size={10}/>}>
              Engajamento
            </StyledMenuButton>
            <StyledMenuList>
              <StyledMenuItem>
                <FaColumns style={{ marginRight: '.4rem' }} />
                Módulo bloqueado
              </StyledMenuItem>

              <StyledMenuItem
                disabled
              >
                <FaColumns style={{ marginRight: '.4rem' }} />
                Dashboard
              </StyledMenuItem>

              <StyledMenuItem
                disabled
              >
                <img src={LogoPec} style={{ marginRight: '.4rem', height: '1rem' }} />
                Pesquisa de engajamento
              </StyledMenuItem>

              <StyledMenuItem
                disabled
              >
                <img src={LogoPulse} style={{ marginRight: '.4rem', height: '1rem' }} />
                Pesquisa Pulse
              </StyledMenuItem>

              <StyledMenuItem
                disabled
              >
                <img src={LogoFO} style={{ marginRight: '.4rem', height: '1rem' }} />
                Radar
              </StyledMenuItem>

              <StyledMenuItem
                disabled
              >
                <FaTachometerAlt style={{ marginRight: '.4rem' }} />
                eNPS
              </StyledMenuItem>

              {Permissoes.usuarioNovo && (
                <StyledMenuItem
                  disabled
                >
                  <FaTachometerAlt style={{ marginRight: '.4rem' }} />
                  eNPS (Cíclico)
                </StyledMenuItem>
              )
              }
            </StyledMenuList>
          </Menu>
        )
        }

        {(GestoresViaCerta() || parseJwt()?.adm === 'True') && Permissoes.engajamento && (
          <Menu>
            <StyledMenuButton rightIcon={<FaChevronDown style={{ marginLeft: '-4px' }} size={10}/>}>
              Engajamento
            </StyledMenuButton>
            <StyledMenuList>
              {
                ((GestoresViaCerta() && permitirFuncao('Engajamento', 'dash')) || parseJwt()?.adm === 'True') && (
                  <StyledMenuItem
                    onClick={() => nav('/Engajamento')}
                  >
                    <FaColumns style={{ marginRight: '.4rem' }} />
                    Dashboard
                  </StyledMenuItem>
                )
              }

              {
                ((GestoresViaCerta() && permitirFuncao('Engajamento', 'pec')) || parseJwt()?.adm === 'True') && (
                  <StyledMenuItem
                    onClick={() => nav('/Engajamento/Gestao')}
                  >
                    <img src={LogoPec} style={{ marginRight: '.4rem', height: '1rem' }} />
                    Gestão de pesquisas de engajamento
                  </StyledMenuItem>
                )
              }

              {
                ((GestoresViaCerta() && permitirFuncao('Engajamento', 'pulse')) || parseJwt()?.adm === 'True') && (
                  <StyledMenuItem
                    onClick={() => redirectPage('PesquisaPulse', 'Index')}
                  >
                    <img src={LogoPulse} style={{ marginRight: '.4rem', height: '1rem' }} />
                    Pesquisa Pulse
                  </StyledMenuItem>
                )
              }

              {((GestoresViaCerta() && permitirFuncao('Engajamento', 'feedback')) || parseJwt()?.adm === 'True') && (
                <StyledMenuItem
                  onClick={() => redirectPage('FeedbackOrganizacao', 'Index')}
                >
                  <img src={LogoFO} style={{ marginRight: '.4rem', height: '1rem' }} />
                  Radar
                </StyledMenuItem>
              )}

              {
                ((GestoresViaCerta() && permitirFuncao('Jornada', 'enps')) || parseJwt()?.adm === 'True') && (
                  <StyledMenuItem
                    onClick={() => nav(Permissoes.usuarioNovo ? '/Jornada/eNps' : '/Engajamento/eNPS')}
                  >
                    <FaTachometerAlt style={{ marginRight: '.4rem' }} />
                    eNPS
                  </StyledMenuItem>
                )
              }

              {Permissoes.usuarioNovo && (GestoresViaCerta() && permitirFuncao('Engajamento', 'enps')) && (
                <StyledMenuItem
                  onClick={() => nav('/Engajamento/eNPS')}
                >
                  <FaTachometerAlt style={{ marginRight: '.4rem' }} />
                  eNPS (Cíclico)
                </StyledMenuItem>
              )
              }

            </StyledMenuList>
          </Menu>
        )}

        {/* {(parseJwt().eid === 'acbf6a22-154e-480d-a465-61b990faa8bd' || Permissoes.usuarioNovo === true) && Permissoes.pesquisa360 &&
          <Menu>
            <StyledMenuButton rightIcon={<FaChevronDown style={{ marginLeft: '-4px' }} size={10}/>}>
              Pesquisa 360
            </StyledMenuButton>
            <StyledMenuList>

              <StyledMenuItem
                onClick={() => nav('/Desempenho/AnaliseDesempenho/Indicativa/Criar')}
              >
                <TbHandClick style={{ marginRight: '.4rem' }} />
                Criar pesquisa indicativa
              </StyledMenuItem>

              <StyledMenuItem
                onClick={() => nav('/Desempenho/AnaliseDesempenho/Quantitativa/Criar')}
              >
                <FaListOl style={{ marginRight: '.4rem' }} />
                Criar pesquisa tradicional
              </StyledMenuItem>

              <StyledMenuItem
                onClick={() => nav('/Desempenho/AnaliseDesempenho/Gestao')}
              >
                <FaCopy style={{ marginRight: '.4rem' }} />
                Gestão de pesquisas
              </StyledMenuItem>

            </StyledMenuList>
          </Menu>
        }

        {
          (Permissoes.usuarioNovo === true) && !Permissoes.pesquisa360 && (
            <Menu>
              <StyledMenuButton rightIcon={<FaChevronDown style={{ marginLeft: '-4px' }} size={10}/>}>
                Pesquisa 360
              </StyledMenuButton>
              <StyledMenuList>
                <StyledMenuItem>
                  <FaColumns style={{ marginRight: '.4rem' }} />
                  Módulo bloqueado
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <TbHandClick style={{ marginRight: '.4rem' }} />
                  Criar pesquisa indicativa
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaListOl style={{ marginRight: '.4rem' }} />
                  Criar pesquisa tradicional
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaCopy style={{ marginRight: '.4rem' }} />
                  Gestão de pesquisas
                </StyledMenuItem>
              </StyledMenuList>
            </Menu>
          )
        } */}

        {
          (Permissoes.organograma) && (
            <Menu>
              <StyledMenuButton rightIcon={<FaChevronDown style={{ marginLeft: '-4px' }} size={10}/>}
              >Organograma
              </StyledMenuButton>
              <StyledMenuList>
                {
                  (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'visao-geral')) && (
                    <StyledMenuItem
                      onClick={() => nav('/Organograma/Dashboard')}
                    >
                      <FaSitemap style={{ marginRight: '.4rem' }} />
                      Visão geral
                    </StyledMenuItem>
                  )
                }

                {
                  (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'areas')) && (
                    <StyledMenuItem
                      onClick={() => nav('/Organograma/Departamentos')}
                    >
                      <FaIdCard style={{ marginRight: '.4rem' }} />
                      Áreas
                    </StyledMenuItem>
                  )
                }

                {
                  (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'funcoes')) && (
                    <StyledMenuItem
                      onClick={() => nav('/Organograma/Cargos')}
                    >
                      <FaBriefcase style={{ marginRight: '.4rem' }} />
                      Funções
                    </StyledMenuItem>
                  )
                }

                {
                  (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'colaboradores')) && (
                    <StyledMenuItem
                      onClick={() => nav('/Organograma/Colaboradores')}
                    >
                      <FaUsersCog style={{ marginRight: '.4rem' }} />
                      Colaboradores
                    </StyledMenuItem>
                  )
                }
              </StyledMenuList>
            </Menu>
          )
        }

        {
          (!Permissoes.organograma) && (
            <Menu>
              <StyledMenuButton rightIcon={<FaChevronDown style={{ marginLeft: '-4px' }} size={10}/>}>
                Organograma
              </StyledMenuButton>
              <StyledMenuList>
                <StyledMenuItem>
                  <FaColumns style={{ marginRight: '.4rem' }} />
                  Módulo bloqueado
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaSitemap style={{ marginRight: '.4rem' }} />
                  Visão geral
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaIdCard style={{ marginRight: '.4rem' }} />
                  Áreas
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaBriefcase style={{ marginRight: '.4rem' }} />
                  Funções
                </StyledMenuItem>

                <StyledMenuItem
                  disabled
                >
                  <FaUsersCog style={{ marginRight: '.4rem' }} />
                  Colaboradores
                </StyledMenuItem>
              </StyledMenuList>
            </Menu>
          )
        }

        {AutorizarPI() &&
          <Menu>
            <StyledMenuButton rightIcon={<FaChevronDown style={{ marginLeft: '-4px' }} size={10}/>}>
              Assessment PI &reg;
            </StyledMenuButton>
            <StyledMenuList>
              {
                (AutorizarAHPI() || parseJwt().adm === 'True' || parseJwt().consultoria === 'True' || permitirFuncao('Organograma', 'visao-geral')) && (
                  <StyledMenuItem
                    onClick={() => nav('/AssessmentPi/Dashboard')}
                  >
                    <FaColumns style={{ marginRight: '.4rem' }} />
                    Dashboard
                  </StyledMenuItem>
                )
              }
              {
                (AutorizarAHPI() || parseJwt().adm === 'True' || parseJwt().consultoria === 'True' || permitirFuncao('Organograma', 'visao-geral')) && (
                  <StyledMenuItem
                    onClick={() => nav('/AssessmentPi/Dashboard2')}
                  >
                    <FaColumns style={{ marginRight: '.4rem' }} />
                    Dashboard job target
                  </StyledMenuItem>
                )
              }

              {
                (AutorizarAHPI() || parseJwt().adm === 'True' || parseJwt().consultoria === 'False' || permitirFuncao('Organograma', 'areas')) && (
                  <StyledMenuItem
                    onClick={() => nav('/AssessmentPi/JobTarget/Create')}
                  >
                    <FaIdCard style={{ marginRight: '.4rem' }} />
                    Novo job target
                  </StyledMenuItem>
                )
              }

            </StyledMenuList>
          </Menu>
        }

        {/* {(parseJwt().eid !== 'acbf6a22-154e-480d-a465-61b990faa8bd' && parseJwt().eid !== 'c5d7423c-5fbc-4917-a667-c2a2ec9693d7') && */}
        {!AutorizarPI() &&
          <Menu>
            <StyledMenuButton rightIcon={<FaChevronDown style={{ marginLeft: '-4px' }} size={10}/>}>
              Assessment PI &reg;
            </StyledMenuButton>
            <StyledMenuList>
              <StyledMenuItem>
                <FaColumns style={{ marginRight: '.4rem' }} />
                Módulo bloqueado
              </StyledMenuItem>

              <StyledMenuItem disabled>
                <FaColumns style={{ marginRight: '.4rem' }} />
                Dashboard
              </StyledMenuItem>

              <StyledMenuItem disabled>
                <FaIdCard style={{ marginRight: '.4rem' }} />
                Novo job target
              </StyledMenuItem>

            </StyledMenuList>
          </Menu>
        }

        {parseJwt().eid === 'acbf6a22-154e-480d-a465-61b990faa8bd' && (
          <Menu>
            <StyledMenuButton onClick={() => nav('/CentralComando')}>
              Central de comando
            </StyledMenuButton>
          </Menu>
        )}
      </Flex>

      <FlexIcon>
        <Menu>
          <MenuButton
            px={0}
            _hover={{ bg: 'none' }}
            bg={'none'}
            as={Button}
            rightIcon={<MdMenu size={'1.5rem'} />}
            _focus={{ bg: 'none' }}
            _active={{ bg: 'none' }}
          ></MenuButton>
          <StyledMenuList>

            {modoFree() && (<StyledMenuItem

            >
              <FaBriefcase style={{ marginRight: '.4rem' }} />
              <Badge colorScheme='red'>Modo FREE </Badge>
            </StyledMenuItem>)}
            {
              (parseJwt().adm === 'True') && (
                <StyledMenuItem
                  onClick={() => nav('/Usuario/Permissoes')}
                >
                  <FaHome style={{ marginRight: '.4rem' }} />
                  Gerenciar permissões
                </StyledMenuItem>
              )
            }

            {
              (parseJwt().adm === 'True') && (
                <StyledMenuItem
                  onClick={() => nav('/Usuario/Empresa')}
                >
                  <FaCog style={{ marginRight: '.4rem' }} />
                  Configuração da empresa
                </StyledMenuItem>
              )
            }

            {
              (parseJwt().adm === 'True') && (
                <StyledMenuItem
                  onClick={() => nav('/Rh/GestaoAgendamentos')}
                >
                <FaPaperPlane style={{ marginRight: '.4rem' }} />
                Gestão de agendamentos
              </StyledMenuItem>
              )
            }

            <StyledMenuItem
              onClick={() => nav('Usuario/AlterarSenha')}
            >
              <MdPassword style={{ marginRight: '.4rem' }} />
              Alterar senha
            </StyledMenuItem>

            <StyledMenuItem
              onClick={() => nav('/Usuario/PerguntasFrequentes')}
            >
              <FaQuestionCircle style={{ marginRight: '.4rem' }} />
              Perguntas frequentes
            </StyledMenuItem>

            <StyledMenuItem
              onClick={() => nav('/Usuario/ChamadoRh')}
            >
              <FaHeadset style={{ marginRight: '.4rem' }} />
              Chamado com RH
            </StyledMenuItem>

            <StyledMenuItem
              onClick={() => logOut()}
            >
              <MdLogout style={{ marginRight: '.4rem' }} />
              Sair
            </StyledMenuItem>
          </StyledMenuList>
        </Menu>
      </FlexIcon>
    </Container>
    {modoFree() && (
      <Alert status='warning'>
        <AlertIcon />
        <Link href='mailto:comercial@arquiteturahumana.com.br'>Sua empresa utiliza o plano gratuito do SAH limitado a 5 colaboradores, para contratar os planos com funcionalidades completas entre em contato com seu consultor de conta</Link>.
      </Alert>)}
  </>
  )
}
