/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react'
import ApexCharts from 'apexcharts'
import { Flex } from '@chakra-ui/react'
import { GraficoPEC, ItemGrafico } from '../../../../interfaces'
import { processString, splitByClosest } from '../../../../../../../../Utils/Helper'

interface iChartProps {
  id: string
  grafico: GraficoPEC
  setGrafico: (blob: string) => void
  legendas?: string[]
  questoesAfetam?: boolean
  alavancagem?: boolean
  labelFormat?: boolean
}

export const BarChartPDF: React.FC<iChartProps> = ({ id, grafico, setGrafico, questoesAfetam, alavancagem, legendas, labelFormat }) => {
  function GetSeries(): any {
    const resp: any[] = []
    if (grafico.serie) {
      for (let i = 0; i < grafico.serie?.length; i++) {
        if (alavancagem && grafico.serieX && grafico.serieX[i]) {
          resp.push(grafico.serieX[i])
        } else {
          resp.push(grafico.serie[i])
        }
      }
    }

    return [{ name: alavancagem ? 'Alavancagem' : 'Média', data: resp }]
  }
  async function GerarGrafico(): Promise<void> {
    const options = {
      colors: questoesAfetam ? ['#da84d5', 'rgb(123, 229, 194)'] : alavancagem ? ['rgb(209, 255, 117)'] : ['rgba(0, 180, 90, 0.9)'],
      series: GetSeries(),
      chart: {
        type: 'bar',
        height: 500,
        width: 1000,
        animations: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter: function(val: number) {
          if (val === -1) {
            return '#'
          } else {
            return val.toFixed(2)
          }
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          },
          distributed: true
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: alavancagem ? ['Alavancagem'] : legendas ?? ['Média'],
        position: 'top',
        fontSize: '16px',
        itemMargin: {
          horizontal: 10
        },
        markers: {
          size: 11,
          offsetX: -3,
          background: 'rgba(0, 180, 90, 0.9)'
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        min: 0,
        max: alavancagem ? grafico.serieX?.some(e => e && e > 1.4) ? 1.6 : grafico.serieX?.some(e => e && e > 1) ? 1.4 : grafico.serieX?.some(e => e && e > 0.6) ? 1 : 0.6 : 10,
        tickAmount: 10,
        show: true,
        labels: {
          formatter: function(val: string) {
            return parseFloat(val).toFixed(1)
          }
        }
      },
      xaxis: {
        categories: labelFormat
          ? grafico.labels?.map(e => processString(e))
          : grafico.labels?.map(e => splitByClosest(e, 28)),
        labels: {
          rotate: 0,
          hideOverlappingLabels: false,
          trim: labelFormat ? undefined : true,
          minHeight: labelFormat ? 180 : undefined,
          style: {
            fontSize: labelFormat ? '11px' : '14px'
          }
        }
      },
      fill: {
        opacity: 1,
        colors: questoesAfetam ? grafico.afetaEngajamento.map(e => e ? 'rgb(123, 229, 194)' : '#da84d5') : alavancagem ? ['rgb(209, 255, 117)'] : ['rgba(0, 180, 90, 0.9)']
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return val.toFixed(2)
          }
        }
      }
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()

    const dataURL = await chart.dataURI().then((value: any) => {
      return value
    })

    setGrafico(dataURL.imgURI)
  }

  useEffect(() => {
    GerarGrafico()
  }, [grafico])

  return (
    <Flex w={'100%'} justifyContent={'center'} position={'relative'}>
      <div id={id}></div>
    </Flex>
  )
}
