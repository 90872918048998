/* eslint-disable @typescript-eslint/no-unused-vars */
import { QuestionBox, ResumoBox } from './styles'
import { Form, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Divider, Flex, useToast } from '@chakra-ui/react'
import { FaChartPie, FaEye, FaFilePdf, FaShare } from 'react-icons/fa'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { ModalDelete } from '../../../../components/ModalDelete'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { iAtributo, iDemografiaPEC, iEscala, PerguntaPec } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { TimelineCriarPEC } from '../Components/Timeline'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { ExportarQuestoesPDF } from './ExportarQuestoes'
import { FormularioPreenchimentoPDF } from './FormularioPreenchimentoPDF'
import Comp1 from '../../../../assets/Competencias-01.png'
import Comp2 from '../../../../assets/Competencias-02.png'
import Comp3 from '../../../../assets/Competencias-03.png'
import Comp4 from '../../../../assets/Competencias-04.png'
import Comp5 from '../../../../assets/Competencias-05.png'
import Comp6 from '../../../../assets/Competencias-06.png'
import Comp7 from '../../../../assets/Competencias-07.png'
import Comp8 from '../../../../assets/Competencias-08.png'
import Comp9 from '../../../../assets/Competencias-09.png'
import Comp10 from '../../../../assets/Competencias-10.png'
import Comp11 from '../../../../assets/Competencias-11.png'
import Comp12 from '../../../../assets/Competencias-12.png'
import Comp13 from '../../../../assets/Competencias-13.png'
import Comp14 from '../../../../assets/Competencias-14.png'
import Comp15 from '../../../../assets/Competencias-15.png'
import Comp16 from '../../../../assets/Competencias-16.png'
import Comp17 from '../../../../assets/Competencias-17.png'
import Comp18 from '../../../../assets/Competencias-18.png'
import Comp19 from '../../../../assets/Competencias-19.png'
import Comp20 from '../../../../assets/Competencias-20.png'
import Comp21 from '../../../../assets/Competencias-21.png'
import Comp22 from '../../../../assets/Competencias-22.png'
import Comp23 from '../../../../assets/Competencias-23.png'
import Comp24 from '../../../../assets/Competencias-24.png'
import Comp25 from '../../../../assets/Competencias-25.png'
import Comp26 from '../../../../assets/Competencias-26.png'
import Comp27 from '../../../../assets/Competencias-27.png'

export interface iModel {
  questoes: PerguntaPec[]
  escala?: iEscala
  formularioAprovado: boolean
  totalAtributos: number
  totalSelecionadas: number
  totalEngajamento: number
  totalDiscursiva: number
  totalNPS: number
  totalENPS: number
  demografia?: iDemografiaPEC
  atributos: iAtributo[]
  listaDemografias: iDemografiaPEC[]
  nomeEmpresa: string
  nomePesquisa: string
}

export interface Questao {
  id: string
  avatar: string
  pergunta: string
  nomeCompetencia: string
  discursiva: boolean
}

export function GetImg(attAvatar: string): any {
  if (attAvatar.includes('svg')) {
    const icone = attAvatar.split('/')[4].replace('.svg', '')
    const value = parseInt(icone.split('-')[1])
    switch (value) {
      case 1: return Comp1
      case 2: return Comp2
      case 3: return Comp3
      case 4: return Comp4
      case 5: return Comp5
      case 6: return Comp6
      case 7: return Comp7
      case 8: return Comp8
      case 9: return Comp9
      case 10: return Comp10
      case 11: return Comp11
      case 12: return Comp12
      case 13: return Comp13
      case 14: return Comp14
      case 15: return Comp15
      case 16: return Comp16
      case 17: return Comp17
      case 18: return Comp18
      case 19: return Comp19
      case 20: return Comp20
      case 21: return Comp21
      case 22: return Comp22
      case 23: return Comp23
      case 24: return Comp24
      case 25: return Comp25
      case 26: return Comp26
      case 27: return Comp27
      default: return undefined
    }
  }
  return `https://sigah.blob.core.windows.net/assets/AtributosIcons/${attAvatar.split('https://cloud.icecloud.com.br/Content/fotos/')[1]}`
}

export const VisualizarFormularioPec: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()
  const { pathname } = useLocation()
  const { pesquisaId } = useParams<{ pesquisaId: string }>()

  const [IsLoading, setIsLoading] = useState(false)

  const [AprovarFormularioIsOpen, setAprovarFormularioIsOpen] = useState(false)
  const [Formulario, setFormulario] = useState<iModel>({
    questoes: [],
    formularioAprovado: true,
    escala: {
      fator: 0,
      id: '',
      nome: '',
      respostas: []
    },
    totalAtributos: 0,
    totalDiscursiva: 0,
    totalEngajamento: 0,
    totalENPS: 0,
    totalNPS: 0,
    totalSelecionadas: 0,
    atributos: [],
    listaDemografias: [],
    nomeEmpresa: '',
    nomePesquisa: ''
  })

  function AprovarFormulario(): void {
    appApi.put(`Pec/${pesquisaId}/AprovarFormulario`).then(() => {
      nav(`${pathname.replace('VisualizarFormulario', 'ConfigurarEnvio')}`)
    }
    ).catch(() => {
      toast({
        title: 'Não foi inserida nenhuma questão no formulário',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 3000
      })
    })
  }

  function getFormulario(): void {
    setIsLoading(true)
    appApi.get(`FormularioPec/${pesquisaId}/Visualizar`)
      .then(resp => {
        setFormulario(resp.data)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function ReturnQuestao(e: PerguntaPec, index: number, isAtrelada?: boolean): any {
    const atributo = Formulario.atributos.find(att => att.id === e.atributoId)
    return <Flex flexDir={'column'} width={'100%'} gap={'1rem'}>
      <Flex key={index} alignItems={'center'} gap={'1rem'}>
        {isAtrelada &&
          <FaShare color='var(--Azul)' size={'1.75rem'}/>
        }
        <QuestionBox>
          <Flex borderRight={'2px dashed var(--Azul)'} flexDir={'column'} minWidth='8rem' maxWidth='8rem' wordBreak={'break-word'} alignItems={'center'} textAlign='center' margin='0rem 1rem' pb={'.25rem'}>
            <img src={atributo?.avatar} />
            <small style={{ width: '80%' }}>{atributo?.nome}</small>
          </Flex>
          <span>{e.pergunta}</span>
          {e.escalaDemografiaId !== null && Formulario.demografia?.demografias?.find(a => a.id === e.escalaDemografiaId) && <Flex justifyContent={'end'} pos={'absolute'} right={'12px'} bottom={'6px'} fontWeight={'700'} fontSize={'13px'} color={'var(--Azul)'} gap={'.325rem'} alignItems={'center'}>
            <FaChartPie color='var(--Azul)'/> Vinculado à {Formulario.demografia?.demografias?.find(a => a.id === e.escalaDemografiaId)?.nome}
          </Flex>}
        </QuestionBox>
      </Flex>
      {e.perguntaAtrelada && <Flex pl={'2.5rem'} width={'100%'}>
        {ReturnQuestao(e.perguntaAtrelada, index, true)}
      </Flex>}
    </Flex>
  }

  function CheckIfIsAtrelada(id: string): boolean {
    return Formulario.questoes.some(e => e.perguntaAtrelada && e.perguntaAtrelada.id === id)
  }

  useEffect(() => {
    getFormulario()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <ModalDelete
        isOpen={AprovarFormularioIsOpen}
        message='Após aprovar o formulário você não poderá mais editá-lo. Deseja continuar?'
        onRequestClose={() => setAprovarFormularioIsOpen(false)}
        onConfirm={AprovarFormulario}
        center
      />

      <TimelineCriarPEC color={['var(--Azul)', 'var(--Azul)', '.', '.', '.']} formularioAprovado={Formulario.formularioAprovado}/>

      <Flex justifyContent={'space-between'} marginBottom='1.75rem'>
        <h1>Verifique e aprove o formulário</h1>
      </Flex>

      <WhiteContainer>
        <Flex flexDir={'column'} gap={'.75rem'}>
          <Flex justifyContent={'space-between'}>
            <h3>Resumo</h3>
          </Flex>

          <Flex justifyContent={'center'} alignItems={'center'} gap={'2rem'}>
            <ResumoBox>
              <Flex flexDir={'column'}>
                {Formulario.escala &&
                  <span>Escala selecionada:<strong> {Formulario.escala.nome}</strong></span>
                }
                <span>Atributos selecionados:<strong> {Formulario.totalAtributos}</strong></span>
                <span>Total de questões:<strong> {Formulario.questoes.length}</strong></span>
              </Flex>
              <Divider orientation='vertical' h={'86px'} opacity={'1'} borderColor={'var(--c6)'}/>
              <Flex flexDir={'column'} fontSize={'14px'}>
                <span>Questões selecionadas:<strong> {Formulario.totalSelecionadas}</strong></span>
                <span>Questões de engajamento:<strong> {Formulario.totalEngajamento}</strong></span>
                <span>Questões discursivas:<strong> {Formulario.totalDiscursiva}</strong></span>
                <span>Questões NPS:<strong> {Formulario.totalNPS}</strong></span>
                <span>Questões eNPS:<strong> {Formulario.totalENPS}</strong></span>
              </Flex>
            </ResumoBox>
            <Flex flexDir={'column'} alignItems={'center'} justifyContent={'center'} gap={'1rem'}>
              <PDFDownloadLink
                document={
                  <ExportarQuestoesPDF
                    model={{
                      atributos: Formulario.atributos,
                      demografias: Formulario.listaDemografias,
                      escala: Formulario.escala,
                      nomeEmpresa: Formulario.nomeEmpresa,
                      nomePesquisa: Formulario.nomePesquisa
                    }}
                  />
                }
                fileName={'Exportar_questoes.pdf'}
              >
                {({ loading }) =>
                  (loading)
                    ? (
                      <ButtonCmp VarColor='Green2' w={'19rem'} leftIcon={<FaFilePdf/>} isLoading>Exportar questões</ButtonCmp>
                      )
                    : <ButtonCmp VarColor='Green2' w={'19rem'} leftIcon={<FaFilePdf/>}>Exportar questões</ButtonCmp>

                }
              </PDFDownloadLink>
              <PDFDownloadLink
                document={
                  <FormularioPreenchimentoPDF
                    model={{
                      atributos: Formulario.questoes,
                      demografias: Formulario.listaDemografias,
                      nomeEmpresa: Formulario.nomeEmpresa,
                      nomePesquisa: Formulario.nomePesquisa,
                      escala: Formulario.escala
                    }}
                  />
                }
                fileName={'Exportar_formulario_preenchimento.pdf'}
              >
                {({ loading }) =>
                  (loading)
                    ? (
                      <ButtonCmp VarColor='Green2' leftIcon={<FaFilePdf/>} w={'19rem'} isLoading>Exportar formulário para preenchimento</ButtonCmp>
                      )
                    : <ButtonCmp VarColor='Green2' leftIcon={<FaFilePdf/>} w={'19rem'}>Exportar formulário para preenchimento</ButtonCmp>

                }
              </PDFDownloadLink>
            </Flex>
          </Flex>

          <Flex gap={'.5rem'} alignItems={'center'}>
            <h3>Questões selecionadas</h3>
            <Flex alignItems={'center'} justifyContent={'center'} fontSize={'.75rem'} bg={'var(--Azul)'} color={'white'} borderRadius={'50%'} fontWeight={'700'} w={'1.5rem'} h={'1.5rem'}>{Formulario.questoes.length}</Flex>
          </Flex>

          <Flex flexDir={'column'} gap={'.75rem'}>
            {
              Formulario.questoes.filter(e => !CheckIfIsAtrelada(e.id)).map((e: PerguntaPec, index) => {
                return (
                  ReturnQuestao(e, index)
                )
              })
            }

          </Flex>
          <Flex justifyContent={'space-between'}>
            {Formulario.escala &&
              <ButtonCmp
                leftIcon={<FaEye />}
                onClick={() => nav(`/Engajamento/PEC/${pesquisaId}/PrevisualizarFormulario`)}
                VarColor='Green2'
              >Pré-visualizar</ButtonCmp>
            }
          </Flex>
        </Flex>
      </WhiteContainer>
      <Flex marginTop={'.75rem'} justifyContent={'end'} gap={'.5rem'}>
        <ButtonCmp VarColor='c6' onClick={() => nav(-1)}>Voltar</ButtonCmp>

        {!Formulario.formularioAprovado && <ButtonCmp
          VarColor='Green2'
          onClick={() => setAprovarFormularioIsOpen(true)}
        >Aprovar formulário</ButtonCmp>}

        {Formulario.formularioAprovado && <ButtonCmp
          VarColor='Green2'
          onClick={() => nav(`${pathname.replace('VisualizarFormulario', 'ConfigurarEnvio')}`)}
        >Continuar</ButtonCmp>}
      </Flex>
    </Body>
  )
}
